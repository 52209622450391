import React, {useEffect, useState} from "react";
import {Box, Button, Divider, Grid, Modal, TextField, Typography} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {companyService} from "../../../../api/services/company";
import {SET_EMPLOYEES} from "../../../../redux/reducers/company";
import {useSnack} from "../../../../utils/useSnack";
import {validateEmail, validateIinOrBin} from "../../../../utils/validationRegexs";
import {Company} from "../../../../types/company";
import {State} from "../../../../redux/store";
import {useTranslation} from "react-i18next";
import {modalStyle} from "../../../../common/modalStyle";
import {t} from "i18next";

export function NewEmployeeModal({
                                     isOpen, onClose
                                 }: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const {snack} = useSnack();

    const state = useSelector((state: State) => state);
    const dispatch = useDispatch();
    const company: Company | null = state.company.company;

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [iin, setIin] = useState("");
    const [iinError, setIinError] = useState("");

    const [highlight, setHighlight] = useState(false);

    const onCancel = () => {
        onClose();
        setUsername("");
        setIin("");
    };

    const onSubmit = async () => {
        if (!company) return;

        if (username && iin) {
            if (!validateEmail(username)) {
                setHighlight(true);
                setUsernameError(t("validation.email_format"));
                snack(t("validation.email_format"), "warning");
            }

            if (!validateIinOrBin(iin)) {
                setHighlight(true);
                setIinError(t("validation.iin_format"));
                snack(t("validation.iin_format"), "warning");
            }

            if (validateEmail(username) && validateIinOrBin(iin)) {
                try {
                    const response = await companyService.saveNewEmployee(
                        company.id, {username, identity: iin}
                    );

                    if (response.status === "error") {
                        switch (response.code) {
                            case "user_not_found":
                                snack(t("snack.error.user_not_found"), "error");
                                break
                            case "company_user_already_exists":
                                snack(t("snack.error.company_user_already_exists"), "error");
                                break
                            default:
                                snack(t("snack.error.add_employee"), "error");
                                break;
                        }
                    }

                    if (response.status === "success") {
                        snack(t("snack.success.employee_added"), "success");
                        onCancel();

                        // update employees table
                        try {
                            const result = await companyService.getEmployees(company.id);
                            dispatch({
                                type: SET_EMPLOYEES,
                                payload: response.data
                            });
                        } catch (err) {
                            snack(t("snack.error.employees"), "error")
                        }
                    }
                } catch (err) {
                    snack(t("snack.error.employees"), "error");
                }
            }
        } else {
            setHighlight(true);
            !username && setUsernameError(t("validation.employee_email_required"));
            !iin && setIinError(t("validation.employee_iin_required"));
            snack(t("snack.warning.empty_fields"), "warning");
        }
    }

    const onUsernameBlur = () => {
        if (username && !validateEmail(username)) {
            setUsernameError(t("validation.email_format"))
        } else if (!username) {
            setUsernameError(t("validation.employee_email_required"))
        }
    }
    const onIinBlur = () => {
        if (iin && !validateIinOrBin(iin)) {
            setIinError(t("validation.iin_format"))
        } else if (!iin) {
            setIinError(t("validation.employee_iin_required"))
        }
    }

    useEffect(() => {
        if (usernameError) setUsernameError("")
    }, [username]);
    useEffect(() => {
        if (iinError) setIinError("")
    }, [iin]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography component="h5" variant="h5"
                            sx={{p: 2, fontWeight: 700, fontSize: {xs: 14, sm: 20}}}
                >
                    {t("cabinet.employees.add_employee_to_company")}
                </Typography>

                <Divider sx={{mb: 1.5, ml: "auto", mr: "auto", width: "100%"}}/>

                <Grid container style={{flexDirection: "column"}} sx={{p: 2}}>
                    <Grid item>
                        <Grid item>
                            <TextField fullWidth
                                       label={t("auction.fields.new_employee.username_email")}
                                       name="username" color="primary"
                                       value={username} onChange={(e) => setUsername(e.target.value)}
                                       error={(highlight && usernameError) ? true : false}
                                       helperText={highlight && usernameError}
                                       onBlur={onUsernameBlur}
                            />
                        </Grid>

                        <Divider sx={{mt: 1.5, mb: 1.5, ml: "auto", mr: "auto", width: "95%"}}/>

                        <Grid item>
                            <TextField fullWidth
                                       label={t("auction.fields.new_employee.iin")}
                                       name="iin" color="primary"
                                       value={iin} onChange={(e) => setIin(e.target.value)}
                                       error={(highlight && iinError) ? true : false}
                                       helperText={highlight && iinError}
                                       onBlur={onIinBlur}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{mt: 1.5, ml: "auto", mr: "auto", width: "100%"}}/>

                <Grid item container spacing={2} justifyContent="flex-end" sx={{p: 2}}>
                    <Grid item xs="auto">
                        <Button fullWidth color="secondary" variant="contained" onClick={onCancel}>
                            {t("cabinet.employees.cancel")}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button fullWidth color="primary" variant="contained" onClick={onSubmit}>
                            {t("cabinet.employees.add_employee")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
