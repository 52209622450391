import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import ruLocale from 'date-fns/locale/ru';
import kkLocale from "date-fns/locale/kk";
import { Box, Button, Divider, Grid, Paper, TextField, TextFieldProps, Typography} from "@mui/material";
import {Field, FieldProps, Formik, FormikProps} from "formik";
import {Dispatch, SetStateAction, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {State} from "../../../redux/store";
import {Announcement, NewAnnouncementForm} from "../../../types/announcement";
import {initialValues, ValidationSchema} from "./formik";
import {formValidation} from "../../../utils/formValidation";
import {useSnack} from "../../../utils/useSnack";
import {dateToString} from "../../../utils/dates";
import {AxiosError} from "axios";
import {adminService} from "../../../api/services/admin";
import {Response} from "../../../types/api";

export default function NewAnnouncement({
                                          announcementEditing, setAnnouncementEditing
                                        }: {
  announcementEditing?: boolean;
  setAnnouncementEditing?: Dispatch<SetStateAction<boolean>>
}) {
  const {t, i18n} = useTranslation();
  const {snack} = useSnack();
  const navigate = useNavigate();
  const state = useSelector((state: State) => state);
  const currentTime: string | null = state.public.currentTime;
  const currentDate = currentTime ? new Date(currentTime) : new Date();
  const formikRef = useRef<FormikProps<NewAnnouncementForm>>(null);

  function onClose() {
    if (announcementEditing && setAnnouncementEditing) {
      setAnnouncementEditing(false)
    } else {
      navigate("/admin-announcements")
    }
  }

  {/* EDITING */
  }
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const announcementId = announcementEditing ? pathArray[pathArray.length - 1] : "";
  const announcement: Announcement = state.announcement.announcement;
  const editingInitialState = announcementEditing ? {
    title: announcement?.title,
    content: announcement?.content,
    publishedTime: new Date(announcement?.publishedTime)
  } : undefined;
  {/* / EDITING */
  }

  async function submit() {
    await formValidation(formikRef);

    if (formikRef.current && formikRef.current.isValid) {
      formikRef.current.submitForm();
    } else {
      snack(t("snack.warning.required_fields"), "warning")
    }
  }

  async function onSubmit(values: NewAnnouncementForm) {
    const {title, content, publishedTime} = values;

    const data = {
      title,
      content,
      publishedTime: dateToString(publishedTime) as string
    }

    try {
      let response: Response<Announcement>;
      if (announcementEditing) {
        response = await adminService.editAnnouncement(announcementId, data);
      } else {
        response = await adminService.saveNewAnnouncement(data);
      }

      if (response && response.data && response.status === "success") {
        snack(
            announcementEditing
                ? t("snack.success.announcement_edited")
                : t("snack.success.announcement_created"),
            "success"
        );
        navigate("/admin-announcements")
        if (setAnnouncementEditing) setAnnouncementEditing(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      console.log(err.response);
      snack(
          announcementEditing
              ? t("snack.error.edit_announcement")
              : t("snack.error.create_announcement"),
          "error"
      );
    }
  };

  return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language == "kk" ? kkLocale : ruLocale}>
        <Paper sx={{pl: 4, pr: 4, pt: 2, pb: 4}} elevation={10}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography component="h5" variant="h5"
                          sx={{fontWeight: 700}}>
                {announcementEditing
                    ? t("cabinet.new_announcement.edit_title")
                    : t("cabinet.new_announcement.title")
                }
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{mt: 2, mb: 6, width: "100%"}}/>

          <Grid container rowSpacing={3}>
            <Formik
                innerRef={formikRef}
                initialValues={editingInitialState || initialValues}
                validationSchema={ValidationSchema(currentDate)}
                onSubmit={onSubmit}
            >
              {(frops: FormikProps<NewAnnouncementForm>) => (
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Field name={"title"}>
                        {({field, form, meta}: FieldProps) => (
                            <TextField {...field}
                                       fullWidth color="primary" size="small"
                                       label={t("auction.fields.announcement.title")}
                                       error={(meta.touched && meta.error) ? true : false}
                                       helperText={meta.touched && meta.error}
                            />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <MobileDateTimePicker
                          disabled={!currentDate}
                          minDateTime={currentDate
                              ? new Date(currentDate)
                              : new Date()}
                          value={frops.values.publishedTime}
                          onChange={(newValue: any) => frops.setFieldValue("publishedTime", newValue)}
                          inputFormat="dd/MM/yyyy HH:mm"
                          label={t("auction.dates.choose_date_and_time")}
                          //cancelText={t("auction.cancel")}
                          //okText="Ок"
                          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                      <Field name={"publishedTime"}>
                        {({ field, form, meta }: FieldProps) => (
                          <TextField {...params} fullWidth size="small" color="primary"
                            label={t("auction.fields.announcement.published_time")}
                            error={(meta.touched && meta.error) ? true : false}
                            helperText={meta.touched && meta.error}
                            InputLabelProps={{ shrink: field.value ? true : false }}
                          />
                        )}
                      </Field>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field name={"content"}>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField {...field}
                        multiline rows={8} fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.announcement.content")}
                        error={(meta.touched && meta.error) ? true : false}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>

        <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

        <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
          <Grid item xs="auto">
            <Button fullWidth variant="contained" size="large"
                    onClick={onClose} color="secondary"
            >
              {t("cabinet.new_announcement.cancel")}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button fullWidth variant="contained" color="primary"
                    onClick={submit} size="large">
              {announcementEditing
                ? t("cabinet.new_announcement.save_changes")
                : t("cabinet.new_announcement.add_announcement")
              }
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  )
}
