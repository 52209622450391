import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import backend from "i18next-http-backend";
import detector from 'i18next-browser-languagedetector';

i18next
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        debug: true,
        interpolation: {
            escapeValue: false
        },
        supportedLngs: ['ru', 'kk']
    });

export default i18next.t;