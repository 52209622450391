import { AxiosResponse } from 'axios';
import { PaginationResponse, Response } from '../../types/api';
import { Auction } from '../../types/auction';
import { Company } from '../../types/company';
import { AuctionTerminationData, ReviewAuctionData } from '../../types/moderator';
import protectedInstance from '../instances/protected';
import {DictionaryObject} from "../../types/dictionary";

async function getAuctions(
	text: string, page: number, pageSize: number, auctionState?: DictionaryObject[], auctionType?: string
) {
	const query = `query=${text}&page=${page}&page_size=${pageSize}` +
                `&states=${auctionState}&type=${auctionType}`;

	return protectedInstance
		.get(`/manager/auctions`, {
			params: {
				page_size: pageSize,
				page,
				query: text,
				type: auctionType,
				states: auctionState?.map((obj: DictionaryObject) => obj.value).join(',')
			}
		})
		.then((res: AxiosResponse<PaginationResponse<Auction[]>>) => res.data)
}

async function getAuction(auctionId: string) {
	return protectedInstance
		.get(`/manager/auction/${auctionId}`)
		.then((res: AxiosResponse<Response<Auction>>) => res.data)
}

async function terminateAuction(auctionId: string, data: AuctionTerminationData) {
	return protectedInstance
		.post(`/manager/auction/${auctionId}/terminate`, data)
		.then((res: AxiosResponse<Response<any>>) => res.data)
}

async function reviewAuction(auctionId: string, data: ReviewAuctionData) {
	return protectedInstance
		.post(`/manager/auction/${auctionId}/review`, data)
		.then((res: AxiosResponse<Response<any>>) => res.data)
}

async function getCompanies(
	text: string, page: number, pageSize: number
) {
	const query = `query=${text}&page=${page}&page_size=${pageSize}`;

	return protectedInstance
		.get(`/companies/all?${query}`)
		.then((res: AxiosResponse<PaginationResponse<Company[]>>) => res.data)
}

export const moderatorService = { 
	getAuctions,
	getAuction,
	terminateAuction,
	reviewAuction,
	getCompanies
};
