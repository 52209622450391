export function setLSCompanyIdx(companyIdx: number) {
  localStorage.setItem("companyIdx", JSON.stringify(companyIdx))
}

export function removeLSCompanyIdx() {
  localStorage.removeItem("companyIdx")
}

export function getLSCompanyIdx() {
  const rawCompanyIdx = localStorage.getItem("companyIdx");
  if (!rawCompanyIdx) { return 0 }
  const parsedCompanyIdx: string = JSON.parse(rawCompanyIdx);
  return +parsedCompanyIdx;
}