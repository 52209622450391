import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { FormikProps, Formik, Field, FieldProps } from "formik";
import { RefObject, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NewCompanyCompanyForm } from "../../../../../types/newCompany";
import { CompanyForm, CompanySize, CompanyOwnership } from "../../../../../utils/enums";
import { useDictionary } from "../../../../../utils/useDictionary";
import { initialValues, ValidationSchema } from "./formik";

export function CompanyFields({
  formikRef
}: {
  formikRef: RefObject<FormikProps<NewCompanyCompanyForm>>;
}) {
  const onSubmit = (values: NewCompanyCompanyForm) => {};
  const { t } = useTranslation();

  const legalForm = useDictionary(
    CompanyForm, 
    (type) => ({value: type, label: t(`legal_form.${type}`)}),
    { value: CompanyForm.kz_too, label: t("legal_form.kz_too") }
  );
  const size = useDictionary(
    CompanySize, 
    (type) => ({value: type, label: t(`size.${type}`)}),
    { value: CompanySize.small, label: t("size.small") }
  );
  const ownershipForm = useDictionary(
    CompanyOwnership, 
    (type) => ({value: type, label: t(`ownership.${type}`)}),
    { value: CompanyOwnership.private_owner, label: t("ownership.private_owner") }
  );

  {/* formik dict values */}
    useEffect(() => {
      formikRef.current?.setFieldValue(
        "legalForm", legalForm.selected, false);
    }, [legalForm.selected]);
    useEffect(() => {
      formikRef.current?.setFieldValue(
        "size", size.selected, false);
    }, [size.selected]);
    useEffect(() => {
      formikRef.current?.setFieldValue(
        "ownershipForm", ownershipForm.selected, false);
    }, [ownershipForm.selected]);
  {/* / formik dict values */}

  return (
    <Formik innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewCompanyCompanyForm>) => (
        <>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h6">
                {t("cabinet.company_details.organization")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"itn"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.itn")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"title"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.title")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"shortTitle"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.short_title")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disablePortal size="small"
                options={legalForm.dictionary}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={legalForm.selected} 
                onChange={legalForm.onSelectChange}
                renderInput={params => 
                  <Field name={"legalForm"}>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField {...params} fullWidth color="primary" 
                        label={t("auction.fields.company.legal_form")}
                        // error={(meta.touched && meta.error) ? true : false}
                        // helperText={meta.touched && meta.error}
                      /> 
                    )}
                  </Field>
                } 
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disablePortal size="small"
                options={size.dictionary}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={size.selected} 
                onChange={size.onSelectChange}
                renderInput={params => 
                  <Field name={"size"}>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField {...params} fullWidth color="primary" 
                        label={t("auction.fields.company.size")}
                        // error={(meta.touched && meta.error) ? true : false}
                        // helperText={meta.touched && meta.error}
                      /> 
                    )}
                  </Field>
                } 
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disablePortal size="small"
                options={ownershipForm.dictionary}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={ownershipForm.selected} 
                onChange={ownershipForm.onSelectChange}
                renderInput={params => 
                  <Field name={"ownershipForm"}>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField {...params} fullWidth color="primary" 
                        label={t("auction.fields.company.ownership_form")}
                        // error={(meta.touched && meta.error) ? true : false}
                        // helperText={meta.touched && meta.error}
                      /> 
                    )}
                  </Field>
                } 
              />
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  )
}