import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';

import 'aos/dist/aos.css';
import {SnackbarProvider} from "notistack";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {useServerTime} from './utils/useServerTime';

function App() {
    const {currentTime} = useServerTime();

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
            <SnackbarProvider
                maxSnack={3} autoHideDuration={3000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Router>
                    <Routes/>
                </Router>

            </SnackbarProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
