import {t} from "i18next";
import * as Yup from "yup";
import { NewCompanyOtherForm } from "../../../../../types/newCompany";

export const initialValues: NewCompanyOtherForm = {
  reviewComment: "",
};

export function ValidationSchema() {
  return Yup.object({
    reviewComment: Yup.string(),
  });
}