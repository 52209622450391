import React from "react";
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import { Field, FieldArrayRenderProps, FieldProps, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { DictionaryObject } from "../../../../../../types/dictionary";
import { NewAuctionRubricForm, RubricItem as RubricItemType } from "../../../../../../types/newAuction";
import { Unit, DeliveryRegion } from "../../../../../../utils/enums";
import { useDictionary } from "../../../../../../utils/useDictionary";
import { initialRubricItem } from "../formik";
import { ThousandsSeparator } from "../../../../../../common/NumberFormat";

export const RubricItem = ({
  idx, onProductModalOpen, frops, arrayHelpers
}: {
  idx: number;
  onProductModalOpen: (itemId: number) => void;
  frops: FormikProps<NewAuctionRubricForm>;
  arrayHelpers: FieldArrayRenderProps
}) => {
  const { values, setFieldValue } = frops;
  const { productType, items } = values;
  const { productCode } = items[idx];
  const { t } = useTranslation();

  const unit = useDictionary(
    Unit, 
    (type) => ({value: type, label: t(`unit.${type}`)}),
    { value: Unit.sht, label: t("unit.sht") }
  );
  const deliveryRegion = useDictionary(
    DeliveryRegion, 
    (type) => ({value: type, label: t(`delivery_region.${type}`)}),
    { value: DeliveryRegion.kz1911, label: t("delivery_region.kz1971") }
  );
  const selected = { unit: unit.selected, deliveryRegion: deliveryRegion.selected };

  React.useEffect(() => {
    setFieldValue(`items[${idx}].unit`, unit.selected, false)
  }, [unit.selected, values.productType]);
  React.useEffect(() => {
    setFieldValue(`items[${idx}].deliveryRegion`, deliveryRegion.selected, false)
  }, [deliveryRegion.selected, values.productType]);

  return (
    <Grid container sx={{ mt: 1 }}>
      <ItemHeader 
        items={values.items} idx={idx} selected={selected}
        arrayHelpers={arrayHelpers} />

      <Grid item container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
        {(productType.value === "custom" || productCode) 
          ? <ItemCodeNameDescr items={items} idx={idx} productType={productType} />

          : <Grid item xs={12} sm={6} md={4} sx={{ alignSelf: "center" }}>
              <Field name={`items[${idx}].productCode`}>
                {({ field, form, meta }: FieldProps) => (
                  <Grid>
                    <Button color={meta.touched && meta.error ? "error" : "primary"} 
                            variant="contained" size="small"
                            onClick={() => onProductModalOpen(idx)}
                    >
                      {t("auction.modals.product.choose_product_code")}
                    </Button>

                    {(meta.touched && meta.error) &&
                      <Typography 
                        color="#d32f2f" fontSize="0.6428571428571428rem" 
                        mt="4px" mx="14px" mb="0"
                      >
                        {meta.error}
                      </Typography>
                    }
                  </Grid>
                )}
              </Field>
            </Grid>
        }
      </Grid>
      
      <Grid item container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Field name={`items[${idx}].price`}>
            {({ field, form, meta }: FieldProps) => (
              <TextField {...field}
                fullWidth color="primary" size="small"
                label={t("auction.fields.rubric.price")}
                error={(meta.touched && meta.error) ? true : false}
                helperText={meta.touched && meta.error}
                onBlur={() => {
                  if (+field.value === 0) form.setFieldValue(`items[${idx}].price`, "");
                }}
                InputProps={{ inputComponent: ThousandsSeparator }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete 
            disablePortal size="small"
            options={unit.dictionary}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={unit.selected} 
            onChange={unit.onSelectChange}
            renderInput={params => 
              <Field name={"unit"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...params} fullWidth color="primary" 
                            label={t("auction.dictionary.unit")}
                            error={(meta.touched && meta.error) ? true : false}
                            helperText={meta.touched && meta.error}
                  /> 
                )}
              </Field>
            } 
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field name={`items[${idx}].amount`}>
            {({ field, form, meta }: FieldProps) => (
              <TextField {...field}
                fullWidth color="primary" size="small"
                label={t("auction.fields.rubric.amount")}
                error={(meta.touched && meta.error) ? true : false}
                helperText={meta.touched && meta.error}
                onBlur={() => {
                  if (+field.value === 0) form.setFieldValue(`items[${idx}].amount`, "");
                }}
                InputProps={{ inputComponent: ThousandsSeparator }}
              />
            )}
          </Field>
        </Grid>
      </Grid>

      <Grid item container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete 
            disablePortal size="small"
            options={deliveryRegion.dictionary}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={deliveryRegion.selected} 
            onChange={deliveryRegion.onSelectChange}
            renderInput={params => 
              <Field name={"deliveryRegion"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...params} fullWidth color="primary" 
                            label={t("auction.dictionary.deliveryRegion")}
                            error={(meta.touched && meta.error) ? true : false}
                            helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            } 
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Field name={`items[${idx}].deliveryLocation`}>
            {({ field, form, meta }: FieldProps) => (
              <TextField {...field}
                fullWidth color="primary" type="text" size="small"
                label={t("auction.fields.rubric.deliveryLocation")}
                error={(meta.touched && meta.error) ? true : false}
                helperText={meta.touched && meta.error}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ItemHeader = ({
  idx, items, arrayHelpers, selected
}: {
  idx: number;
  items: RubricItemType[];
  arrayHelpers: FieldArrayRenderProps;
  selected: { unit: DictionaryObject | null, deliveryRegion: DictionaryObject | null }
}) => {
  const { t } = useTranslation();
  const { productCode, price, amount, deliveryLocation } = items[idx];
  const notEmptyCondition = [productCode, price, amount, deliveryLocation]
    .some(value => value);

  return (
    <Grid container justifyContent="space-between">
      {items.length > 1 
        ? <React.Fragment>
            <Grid item style={{ alignSelf: "center" }}>
              <b>{t("auction.rubric.position")} №{idx + 1}</b>
            </Grid>

            <Grid item style={{ alignSelf: "center" }}>
            <Button color="error" variant="contained" size="small"
                    onClick={() => arrayHelpers.remove(idx)}>
              {t("auction.rubric.remove_position")}
            </Button>
            </Grid>
          </React.Fragment>

        : <React.Fragment>
            <Grid item style={{ alignSelf: "center" }}>
              <b>{t("auction.rubric.position")}</b>
            </Grid>

            {notEmptyCondition &&
              <Grid item style={{ alignSelf: "center" }}>
                <Button color="error" variant="contained" size="small"
                        onClick={() => arrayHelpers.replace(0, {
                          ...initialRubricItem, 
                          unit: selected.unit,
                          deliveryRegion: selected.deliveryRegion
                        })}>
                  {t("auction.rubric.reset_position")}
                </Button>
              </Grid>
            }
          </React.Fragment>
      }
    </Grid>
  )
}

const ItemCodeNameDescr = ({
  items, productType, idx
}: {
  items: RubricItemType[];
  productType: DictionaryObject;
  idx: number;
}) => {
  const { t } = useTranslation();
  const { productCode } = items[idx];
  const readOnlyCondition = false//productType.value !== "custom";

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4}>
        <Field name={`items[${idx}].productCode`}>
          {({ field, form, meta }: FieldProps) => (
            <TextField {...field}
              fullWidth color="primary" type="text" size="small"
              label={productCode 
                ? t("auction.fields.rubric.productCode")
                : t("auction.rubric.write_custom_product_code")
              }
              InputProps={{ readOnly: readOnlyCondition }}
              error={(meta.touched && meta.error) ? true : false}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>
      </Grid>

      <Grid item xs={12} sm={6} md={8}>
        <Field name={`items[${idx}].productName`}>
          {({ field, form, meta }: FieldProps) => (
            <TextField {...field}
              fullWidth color="primary" type="text" size="small"
              label={t("auction.fields.rubric.productName")}
              InputProps={{ readOnly: readOnlyCondition }}
              error={(meta.touched && meta.error) ? true : false}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sm={8} md={12} sx={{ my: 2 }}>
        <Field name={`items[${idx}].productBrief`}>
          {({ field, form, meta }: FieldProps) => (
            <TextField {...field} multiline rows={4}
              fullWidth color="primary" type="text" size="small"
              label={t("auction.fields.rubric.productBrief")}
              InputProps={{ readOnly: readOnlyCondition }}
              error={(meta.touched && meta.error) ? true : false}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>
      </Grid>
    </React.Fragment>
  )
}
