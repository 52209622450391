type Time = {
  hours: string | number;
  minutes: string | number;
  seconds: string | number;
}

export function dateStringToTime(dateString: string | null): Time | null {
  if (!dateString) { return null }
  const date = new Date(dateString);

  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();
  let seconds: number | string = date.getSeconds();

  if (hours < 10 ) hours = '0' + hours;
  if (minutes < 10 ) minutes = '0' + minutes;
  if (seconds < 10) seconds = '0' + seconds;

  return { hours, minutes, seconds }
}

function minTwoDigits(n: number) {
  return (n < 10 ? '0' : '') + n;
}

export function dateToMuiDateTimeString(date: Date | null) {
  if (date) {
    const day = minTwoDigits(date.getDate());
    const month = minTwoDigits(date.getMonth());
    const year = date.getFullYear();
    const hours = minTwoDigits(date.getHours());
    const minutes = minTwoDigits(date.getMinutes());

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }
}

export function msToTime(duration: number) {
  let seconds: string | number = Math.floor((duration / 1000) % 60),
      minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
      hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}

export function dateToString(date: Date | null | undefined) {
  if (!date) { return undefined }

  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours().toString(),
      minutes = d.getMinutes().toString();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  if (hours.length < 2) 
      hours = '0' + hours;
  if (minutes.length < 2) 
      minutes = '0' + minutes;

  return `${year}-${month}-${day}T${hours}:${minutes}:00.000`;
};

export function dateAfterDays(date: Date | null, days: number) {
  if (date) {
    const dateInMs = date.getTime();
    const daysInMs = (3600000 * 24) * 30;
  
    return new Date(dateInMs + daysInMs);
  }
}

export function dateAfterHours(date: Date | null, hours: number) {
  if (date) {
    const dateInMs = date.getTime();
    const hoursInMs = 3600000 * hours;
  
    return new Date(dateInMs + hoursInMs);
  }
}