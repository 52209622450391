import {t} from "i18next";
import * as Yup from "yup";
import { NewAuctionFilesForm } from "../../../../../types/newAuction";

export const initialValues: NewAuctionFilesForm = {
  attachments: []
};

export function ValidationSchema() {
  return Yup.object({
    attachments: Yup.array().min(1, t("validation.required")),
  });
}
