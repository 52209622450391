import {t} from "i18next";
import * as Yup from "yup";
import { NewCompanyOwnerForm } from "../../../../../types/newCompany";

export const initialValues: NewCompanyOwnerForm = {
  ownerUser: null,
  ownerIdentity: ""
};

export function ValidationSchema() {
  return Yup.object({
    ownerUser: Yup.object({
      username: Yup.string()
        .required(t("validation.required"))
        .email(t("validation.incorrect"))
    }),
    ownerIdentity: Yup.string()
      .required(t("validation.required"))
      .matches(/^[0-9]*$/, t("validation.incorrect"))
      .min(12, t("validation.incorrect"))
      .max(12, t("validation.incorrect"))
  });
}