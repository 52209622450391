import { InputBaseComponentProps } from "@mui/material";
import { forwardRef, PropsWithChildren, ForwardedRef } from "react";
import NumberFormat from "react-number-format";

export const PhoneMaskInputComponent = forwardRef((
  props: PropsWithChildren<InputBaseComponentProps>, 
  ref: ForwardedRef<unknown>
) => {
  return (
    <NumberFormat {...props} defaultValue="" 
      value={props.value} 
      format="+7 (###) ###-##-##" 
      mask="_"
    />
  )
});

export const ThousandsSeparator = forwardRef((
  props: PropsWithChildren<InputBaseComponentProps>, 
  ref: ForwardedRef<unknown>
) => {
  return (
    <NumberFormat {...props} defaultValue="" 
      value={props.value} 
      thousandsGroupStyle="thousand"
      decimalSeparator="."
      displayType="input"
      type="text"
      thousandSeparator={" "}
      allowNegative={false}
    />
  )
});