import { SxProps, Theme } from "@mui/material";

export const modalStyle: SxProps<Theme> | undefined = { 
  width: "100%", 
  maxWidth: { xs: '300px', sm: '600px' }, 
  margin: { xs: '10px', sm: '5px 20px' }, 
  p: { xs: 2, sm: 3 },
  bgcolor: 'background.paper', 
  boxShadow: 24,
  transform: 'translate(-50%, -50%)',
  position: 'absolute', 
  top: '50%', 
  left: '47%',
  transition: "height 1s"
};