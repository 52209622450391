/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { useSnack } from '../../../../../utils/useSnack';
import { ForgotPasswordFinalStepData, ForgotPasswordFirstStepData } from '../../../../../types/auth';
import { authService } from '../../../../../api/services/auth';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

type FirstStepValues = {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}

type FinalStepValues = {
  code: string;
}

const Form = () => {
  const { t } = useTranslation();
  const validationSchemas = [
    yup.object({
      email: yup
        .string()
        .trim()
        .required(t("validation.email_required"))
        .email(t("validation.email_format")),
      newPassword: yup
        .string()
        .trim()
        .required(t("validation.new_password_required")),
      confirmNewPassword: yup
        .string()
        .trim()
        .required(t("validation.new_password_confirm_required"))
        .oneOf([yup.ref('newPassword')], t("validation.passwords_dont_match")),
    }),
    yup.object({
      code: yup
        .string()
        .min(6, t("validation.code_six_format"))
        .max(6, t("validation.code_six_format"))
        .required(t("validation.code_required"))
    }),
  ];

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { snack } = useSnack();
  const navigate = useNavigate();

  // if actionId is empty = form is in first step
  // if actionId is not empty = form is in final step
  const [actionId, setActionId] = useState("");
  
  const initialValues = [
    {
      email: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    {
      code: "",
    }
  ];

  async function forgotPasswordFirstStep(data: ForgotPasswordFirstStepData) {
    const recaptchaToken = await executeRecaptcha?.("login");
    if (!recaptchaToken) { snack(t("snack.error.default"), "error"); return }

    const response = await authService.forgotPasswordFirstStep(data, recaptchaToken);

    if (response.status === "success" && response.data) {
      snack(t("snack.code_sent_to_email"), "success");
      return response.data.id;
    }
    else { snack(t("snack.error.default"), "error") }
  }

  async function forgotPasswordFinalStep(data: ForgotPasswordFinalStepData, actionId: string) {
    const response = await authService.forgotPasswordFinalStep(data, actionId);

    if (response.status === "success") {
      snack(t("snack.success.password_changed"), "success");
      navigate("/page-login");
    }
    else if (response.code === "action_code_invalid") {
      snack(t("snack.error.action_code_invalid"), "error")
    }
    else if (response.code === "action_outdated") {
      snack(t("snack.error.action_outdated"), "error")
      setActionId("");
    }
    else { snack(t("snack.error.default"), "error") }
  }

  async function onSubmit(values: FirstStepValues | FinalStepValues) {
    let data: ForgotPasswordFirstStepData | ForgotPasswordFinalStepData;

    try {
      // first step
      if (!actionId) {
        const { email, newPassword } = values as FirstStepValues;
        data = {
          username: email,
          password: newPassword
        };
        const id = await forgotPasswordFirstStep(data as ForgotPasswordFirstStepData);
        if (id) setActionId(id);
      }
      // final step
      else {
        const { code } = values as FinalStepValues;
        data = {
          code
        };
        forgotPasswordFinalStep(data as ForgotPasswordFinalStepData, actionId);
      }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response);
      snack(t("snack.error.default"), "error");
    }
  };

  const formik = useFormik({
    initialValues: initialValues[!actionId ? 0 : 1],
    validationSchema: validationSchemas[!actionId ? 0 : 1],
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
        >
          {t("forgot_password_page.reset_password")}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {t("forgot_password_page.forgot_password")}
        </Typography>
        <Typography color="text.secondary">
          {t("forgot_password_page.enter_email_and_new_password")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("forgot_password_page.enter_email")}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{ 
                readOnly: !actionId ? false : true 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("forgot_password_page.enter_new_password")}
            </Typography>
            <TextField
              label={`${t("inputs.password")} *`}
              variant="outlined"
              name={'newPassword'}
              type={'password'}
              fullWidth
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              InputProps={{ 
                readOnly: !actionId ? false : true 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("forgot_password_page.confirm_new_password")}
            </Typography>
            <TextField
              label={`${t("inputs.confirm_password")} *`}
              variant="outlined"
              name={'confirmNewPassword'}
              type={'password'}
              fullWidth
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
              helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
              InputProps={{ 
                readOnly: !actionId ? false : true 
              }}
            />
          </Grid>
          {actionId
            ? <Grid item xs={12}>
                <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                  {t("forgot_password_page.enter_code_from_email")}
                </Typography>
                <TextField
                  label={`${t("inputs.code")} *`}
                  variant="outlined"
                  name={'code'}
                  type={'text'}
                  fullWidth
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
            : null
          }
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  component={Link}
                  href={'/page-login'}
                >
                  {t("forgot_password_page.back_to_login")}
                </Button>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                {!actionId 
                  ? t("forgot_password_page.continue") 
                  : t("forgot_password_page.update_password")
                }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
