import {t} from "i18next";
import * as Yup from "yup";
import { NewAuctionRubricForm, RubricItem } from "../../../../../types/newAuction";

export const initialRubricItem: RubricItem = {
  productCode: "", 
  productName: "", 
  productBrief: "",
  price: "", 
  unit: { value: "", label: "" }, 
  amount: "",
  deliveryRegion: { value: "", label: "" }, 
  deliveryLocation: "", 
  language: "russian"
}

export const initialValues: NewAuctionRubricForm = {
  productType: { value: "", label: "" },
  items: [initialRubricItem],
};

export function ValidationSchema() {
  return Yup.object({
    productType: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }),
    items: Yup.array()
      .of(
        Yup.object().shape({
          productCode: Yup.string().required(t("validation.required")),
          productName: Yup.string().required(t("validation.required")),
          productBrief: Yup.string().required(t("validation.required")),
          price: Yup.string().required(t("validation.required")),
          unit: Yup.object({ 
            value: Yup.string().required(t("validation.required")), 
          }),
          amount: Yup.string().required(t("validation.required")),
          deliveryRegion: Yup.object({ 
            value: Yup.string().required(t("validation.required")), 
          }),
          deliveryLocation: Yup.string().required(t("validation.required")),
        })
      )
      .required(),
  });
}