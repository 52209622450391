import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from "react-redux";
import { store } from './redux/store';
import "./i18n";
import { CircularProgress } from '@mui/material';

function Loading() {
  return (
    <div style={{ 
      display: "flex", justifyContent: "center", alignItems: "center", 
      width: "100%", height: "100vh" 
    }}>
      <CircularProgress color="primary" />
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    {/* <Suspense fallback={<Loading />}> */}
      <ReduxProvider store={store}>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </ReduxProvider>
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
