import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Chip, Divider, Grid, LinearProgress, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { Auction } from "../../../../types/auction";
import { State } from "../../../../redux/store";
import { msToTime } from "../../../../utils/dates";
import { Company } from "../../../../types/company";
import { auctionService } from "../../../../api/services/auction";
import { useSnack } from "../../../../utils/useSnack";
import { auctionStateArray } from "../../../../utils/auctionStateArray";
import { AuctionState, AuctionType, BidDirection, StorageType } from "../../../../utils/enums";
import { Rubric } from "./Card/components";
import { BidItemPrice } from "../../../../types/bids";
import digitsOnly from "../../../../utils/digitsOnly";
import { generateString } from "../../../../utils/generateString";
import { switchStatusText } from "../../../../utils/switchAuctionText";
import NoRowsOverlay from "../../../../common/NoRowsOverlay";
import { useDictionary } from "../../../../utils/useDictionary";
import useSignature from "../../../../api/services/signature";
import { b64EncodeUnicode } from "../../../../utils/base64";
import { Dictionary } from "../../../../types/dictionary";
import { Column, columnStyle, CompanyTitleColumn, DateColumn } from "../../../../common/Columns";
import {RubricItem} from "../../../../types/newAuction";

export type SigningBundle = {
	signature: string;
	onSign: () => Promise<void>;
	isSigning: boolean;
	signingError: string;
	storageType: Dictionary;
}

export default function Bids({ 
	auctionId, auction, getAuction, participant
}: { 
	auctionId: string;
	auction: Auction | null;
	getAuction: () => void;
	participant: boolean;
}) {
	const { t } = useTranslation();
	const { snack } = useSnack();
	const state = useSelector((state: State) => state);
	const currentDate: Date | null = state.public.currentTime;
	const company: Company | null = state.company.company;

	const initialItemsPrices = auction
		? auction.items.map(() => ({ productCode: "", price: "" }))
		: [];

	const [itemsPrices, setItemsPrices] = useState<BidItemPrice[]>(initialItemsPrices);
	function getPricesTotal(array: BidItemPrice[]) {
		if (!auction) return;

		const pricesTotal = array.reduce((previousValue, currentValue, idx) => {
			const curVal = currentValue["price"].includes(" ") ? currentValue["price"].replace(/\s/g, "") : currentValue["price"]
			return previousValue + (+curVal * +auction.items[idx].amount)
		}, 0);

		return pricesTotal;
	};
	const totalItemsPrices = getPricesTotal(itemsPrices);


	{/* SIGNING */}
		const [signature, setSignature] = useState("");
		
		const storageType = useDictionary(
			StorageType, 
			(type) => ({value: type, label: t(`storage_type.${type}`)}),
			{ value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
		);

		const { signXML } = useSignature();

		const [isSigning, setIsSigning] = useState(false);
		const [signingError, setSigningError] = useState("");

		async function onSign() {
			if (!itemsPrices.every((item) => item.price)) { 
				snack(t("snack.warning.item_price_required"), "warning");
				return
			}
			if (!itemsPrices.every(item => (+item.price?.replace(/[\s,]+/g, '') || 0)  > 0.1)) {
				snack(t("snack.warning.item_price_more_than_zero"), "warning");
				return
			}
			if (auction?.items.some((item: any, idx: number) => +item?.price < +itemsPrices[idx]?.price)) {
				snack(t("snack.warning.item_price_less_than_initial_price"), "warning")
				return
			}

			try {
				if (!company || !storageType.selected || !auction) return;
				setIsSigning(true);

				const bidDetails = {
					documentType: t("auction.bids.documentType"),
					auctionNo: auction.auctionNo,
					companyItn: company.itn,
				}
				const bidDetailsXml = `<bid>${b64EncodeUnicode(JSON.stringify(bidDetails))}</bid>`
				const signedXml = await signXML(bidDetailsXml, storageType.selected.value as StorageType);
				setSignature(signedXml);
				snack(t("snack.success.bid_signed"), "success");
			}
			catch (error) {
				console.error("ERROR", error);

				if (error === "NO_NCALAYER") {
					snack(t("snack.error.no_ncalayer"), "error")
				}
				else if (error === "storage.empty") { snack(t("snack.error.storage_empty"), "error") }
			}
			finally { setIsSigning(false) }
		}

		const signingBundle={
			signature, onSign, isSigning, signingError, storageType
		}
	{/* / SIGNING */}

	function onItemPriceChange(idx: number, code: string, newPrice: string) {
		setItemsPrices(prev => prev.map(({ productCode, price }, index) => {
			if (idx === index) {
				if (newPrice) return { productCode: code, price: newPrice }
				return { productCode: "", price: "" }
			}
			return { productCode, price }
		}))
	}

	const lastBid = auction?.bids ? auction.bids[auction.bids.length - 1].gross : auction?.gross;  
	const nextBid = (lastBid && auction) 
		? auction.bidDirection === BidDirection.increase
			? Math.round((lastBid + auction.bidAmount) * 100) / 100
			: Math.round((lastBid - auction.bidAmount) * 100) / 100
		: undefined;
	const bestBid = auction?.bids 
		? auction.bidDirection === BidDirection.increase
			? auction.bids.reduce((a, b)=> a.gross > b.gross ? a : b).gross 
			: auction.bids.reduce((a, b)=> a.gross < b.gross ? a : b).gross 
		: undefined;

	const [bidding, setBidding] = useState(false);

	const [timeSinceStart, setTimeSinceStart] = useState("");
	const [timeTillEnd, setTimeTillEnd] = useState("");
	useEffect(() => {
		if (currentDate && auction?.bidOpenTime) {
			const msSinceStart = currentDate.getTime() - new Date(auction.bidOpenTime).getTime();
			const msTillEnd = new Date(auction.bidCloseTime).getTime() - currentDate.getTime();
			setTimeSinceStart(msToTime(msSinceStart));
			setTimeTillEnd(msToTime(msTillEnd));
		}
	}, [currentDate]);

	async function placeSteppingBid() {
		if (!company) { return }

		try {
			setBidding(true)
			const bidsResponse = await auctionService.getBids(company.id, auctionId);
			if (!bidsResponse.data) { return }

			const data = { 
				companyId: company.id, 
				biddingKey: bidsResponse.data.biddingKey 
			};
			const response = await auctionService.placeSteppingBid(auctionId, data);
			if (response && response.status === "success") {
				getAuction();
				snack(t("snack.success.bid_placed"), "success");
			}
			else { snack(t("snack.error.bid_place"), "error"); }
		}
		catch (error) {
			const err = error as AxiosError;
			snack(t("snack.error.bid_place"), "error");
		}
		finally { setBidding(false) }
	}

	async function placeBid() {
		if (!company) { return }
		
		try {
			setBidding(true);
			const data = { 
				companyId: company.id, 
				items: itemsPrices.map(item => ({
					...item,
					price: item.price?.replace(/[\s,]+/g, '')
				})),
				attachments: [],
				signature: b64EncodeUnicode(signature)
			};

			const response = await auctionService.placeBid(auctionId, data);
			if (response && response.status === "success") {
				getAuction();
				snack(t("snack.success.bid_placed"), "success");
			}
			else { snack(t("snack.error.bid_place"), "error"); }
		}
		catch (error) {
			const err = error as AxiosError;
			snack(t("snack.error.bid_place"), "error");
		}
		finally { setBidding(false) }
	}

	return (
		<Box>
			<Grid item xs={12} sx={{ mb: 7 }}>
				<Typography fontSize={20} sx={{ mb: 1 }}>
					{auction
						? auctionStateArray("before", AuctionState.opened).includes(auction.state)
							? `${t("auction.bids.auction_open_date")}: ${auction?.bidOpenTime.replace('T', " ")}`
							: `${t("auction.bids.auction_close_date")}: ${auction?.bidCloseTime.replace('T', " ")}`
						: "- - -"
					}
				</Typography>
				<Typography fontSize={20}>
					{switchStatusText(auction)}: {t(`auction_state.${auction?.state}`)}
				</Typography>
			</Grid>

			{auction
				? auctionStateArray("andAfter", AuctionState.opened).includes(auction.state)
					? <Grid container columnGap={3} rowGap={3}>
							<Grid item sm={12} md={auction.auctionType === AuctionType.auction ? 6.5 : 12}>
								<Grid item xs={12} sx={{ mb: 3 }}>
									<Typography variant="h6">
										{t("auction.bids.information")}
									</Typography>
								</Grid>
			
								<Grid container spacing={2}>
									{auction.auctionType === AuctionType.auction
										? <Grid item xs={12} sm={6}>
												<TextField
													fullWidth color="primary" type="text" size="small"
													label={t("auction.fields.bid.starting_price")}
													value={auction?.gross || "- - -"}
													InputProps={{ readOnly: true }}
												/>
											</Grid>
										: null
									}
									
									{auction.state === AuctionState.opened
										? <>
												<Grid item xs={12}>
													<TextField
														fullWidth color="primary" type="text" size="small"
														label={t("auction.fields.bid.end_date")}
														value={auction?.bidCloseTime?.replace('T', " ") ?? "- - -"}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														fullWidth color="primary" type="text" size="small"
														label={t("auction.fields.bid.duration")}
														value={timeSinceStart || "- - -"}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												{auction.auctionType === AuctionType.auction
													? <Grid item xs={12} sm={6}>
															<TextField
																fullWidth color="primary" type="text" size="small"
																label={t("auction.fields.bid.current_price")}
																value={auction?.bids ? auction.bids[auction.bids.length - 1].gross : "- - -"}
																InputProps={{ readOnly: true }}
															/>
														</Grid>
													: null
												}
											</>
										: null
									}
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth color="primary" type="text" size="small"
											label={t("auction.fields.bid.bids_count")}
											value={auction?.bidCount || "- - -"}
											InputProps={{ readOnly: true }}
										/>
									</Grid>

									{auction.auctionType === AuctionType.auction
										? <>
												<Grid item xs={12} sm={6}>
													<TextField
														fullWidth color="primary" type="text" size="small"
														label={t("auction.dictionary.bidStep")}
														value={auction ? t(`bid_step.${auction.bidStep}`) : "- - -"}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<TextField
														fullWidth color="primary" type="text" size="small"
														label={t("auction.dictionary.bidDirection")}
														value={auction ? t(`bid_direction.${auction.bidDirection}`) : "- - -"}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
											</>
										: null
									}
								</Grid>

								<Divider sx={{ my: 3 }} />

								<Grid container spacing={2}>
									{auction.state === AuctionState.opened
										? <Grid item xs={12}>
												<TextField
													fullWidth color="primary" type="text" size="small"
													label={t("auction.fields.bid.time_till_end")}
													value={timeTillEnd || "- - -"}
													InputProps={{ readOnly: true }}
												/>
											</Grid>
										: null
									}
									{auction.auctionType === AuctionType.auction
										? <Grid item xs={12}>
												<TextField
													fullWidth color="primary" type="text" size="small"
													label={t("auction.fields.bid.best_bid")}
													value={bestBid || "- - -"}
													InputProps={{ readOnly: true }}
												/>
											</Grid>
										: null
									}
								</Grid>

								<Divider sx={{ my: 3 }} />

								{(auction.state === AuctionState.opened && participant)
									? bidding 
										? <Box>
												<LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
												<Typography>
													{t("auction.bids.bid_placing")}
												</Typography>
											</Box>
										: <>      
												<Grid item xs={12} sx={{ mb: 3 }}>
													<Typography variant="h6">
														{auction.isBidPlaced
															? t("auction.bids.bid_already_placed")
															: t("auction.bids.new_bid")
														}
													</Typography>
												</Grid>
			
												{auction.auctionType === AuctionType.auction
													? <Grid container spacing={2}>
															<Grid item xs={12} sm={7}>
																<TextField
																	fullWidth color="primary" type="text" size="small"
																	label={t("auction.fields.bid.your_bid")}
																	value={nextBid || "- - -"}
																	InputProps={{ readOnly: true }}
																/>
															</Grid>
															<Grid item xs={12} sm={5}>
																<Button fullWidth variant="contained" size="large" color="primary"
																	onClick={placeSteppingBid} disabled={bidding}
																>
																	{t("auction.bids.place_bid")}
																</Button>
															</Grid>
														</Grid>

													//
													// TENDER
													//
													: <Rubric 
															auction={auction} 
															itemsPrices={itemsPrices}
															onItemPriceChange={onItemPriceChange}
															placeBid={placeBid}
															totalItemsPrices={totalItemsPrices}
															signingBundle={signingBundle}
														/>
													//
													// TENDER
													//
												}
											</>
									: null
								}

								{
									(
										auction.auctionType !== AuctionType.auction 
										&& !participant 
										&& auctionStateArray("after", AuctionState.opened).includes(auction.state)
									)
										? <Grid container spacing={2}>
												<Grid item xs={12} sx={{ mb: 3 }}>
													<Typography variant="h6">
														{t("auction.bids.placed_bids")}
													</Typography>
												</Grid>

												{(auction.bids && auction.bids.length)
													? auction.bids.map((bid, idx) => (
															<Paper key={idx} sx={{ width: "100%", mb: 10, p: { xs: 2, sm: 3 } }} elevation={5}>
																<Grid item xs={12} sx={{ mb: 3 }}>
																	<Typography variant="h6">
																		{t("auction.bids.supplier")} {bid.company.shortTitle}
																	</Typography>
																</Grid>

																<Grid container columnGap={3} rowGap={3}>
																	<Grid item key={idx} container spacing={3} xs={12}>
																		<Grid item xs={12} sm={6}>
																			<TextField
																				fullWidth color="primary" type="text" size="small"
																				label={t("auction.fields.companyTitle")}
																				value={bid.company.title}
																				InputProps={{ readOnly: true }}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6}>
																			<TextField
																				fullWidth color="primary" type="text" size="small"
																				label={t("auction.fields.companyItn")}
																				value={digitsOnly(bid.company.itn)}
																				InputProps={{ readOnly: true }}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6}>
																			<TextField
																				fullWidth color="primary" type="text" size="small"
																				label={t("auction.fields.bid.placed")}
																				value={bid.registrationTime?.replace('T', " ") ?? "- - -"}
																				InputProps={{ readOnly: true }}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6}>
																			<TextField
																				fullWidth color="primary" type="text" size="small"
																				label={t("auction.fields.actualAddress")}
																				value={bid.company.actualAddress}
																				InputProps={{ readOnly: true }}
																			/>
																		</Grid>
																	</Grid>

																	<Rubric auction={auction} isOwned={true} bidIdx={idx} />
																</Grid>
															</Paper>
														))
													: null
												}
											</Grid>
										: null
								}
							</Grid>
			
							{auction.auctionType === AuctionType.auction
								? <Grid item sm={12} md={5} sx={{ width: "100%" }}>
										<Grid item xs={12} sx={{ mb: 3 }}>
											<Typography variant="h6">
												{t("auction.bids.placed_bids")}
											</Typography>
										</Grid>
					
										<Grid item xs={12}>
											<div style={{ height: 480, width: '100%' }}>
												<DataGrid density="standard" 
													components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
													initialState={{
														sorting: {
															sortModel: [{ field: 'registrationTime', sort: 'desc' }],
														},
													}}
													rows={auction?.bids ? auction.bids.map((bid, idx) => ({ ...bid, id: idx })) : []} 
													columns={[
														CompanyTitleColumn(200, columnStyle),
														Column("gross", 150, columnStyle),
														DateColumn("registrationTime", 200, columnStyle)
													]} 
												/>
											</div>
										</Grid>
									</Grid>
								: null
							}
						</Grid>

					: <Box>
							<Grid item xs={12} sx={{ mb: 1 }}>
								<Typography variant="h6">
									{auction
										? auctionStateArray("before", AuctionState.opened).includes(auction.state)
											? t("auction.bids.this_stage_not_yet")
											: t("auction.bids.this_stage_ended")
										: "- - -"
									}
								</Typography>
							</Grid>
						</Box>
				: null
			}

		</Box>
	)
}
