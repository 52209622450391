import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Chip, Divider, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { auctionService } from "../../../../../api/services/auction";
import { State } from "../../../../../redux/store";
import digitsOnly from "../../../../../utils/digitsOnly";
import { useSnack } from "../../../../../utils/useSnack";
import { Auction, AuctionFile } from "../../../../../types/auction";
import { useTranslation } from "react-i18next";
import { Company } from "../../../../../types/company";
import { publicService } from "../../../../../api/services/public";
import { Reviews } from "../../../../../common/Reviews";
import { AuctionType, StorageType } from "../../../../../utils/enums";
import { Rubric } from "./components";
import useSignature from "../../../../../api/services/signature";
import { companyService } from "../../../../../api/services/company";
import { b64DecodeUnicode, b64EncodeUnicode } from "../../../../../utils/base64";
import { useDictionary } from "../../../../../utils/useDictionary";
import SignatureBtn from "../../../../../common/SignatureBtn";

export default function AuctionCard({
  auctionType, auctionPath, auction, setAuction, loading, setLoading, setAuctionEditing
}: {
  auctionType?: AuctionType;
  auctionPath: string;
  auction: Auction | null;
  setAuction: React.Dispatch<React.SetStateAction<Auction | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAuctionEditing?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { snack } = useSnack();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const state = useSelector((state: State) => state);
  const currentDate: Date | null = state.public.currentTime;

  const company: Company | null = state.company.company;
  const auctionId = pathArray[pathArray.length - 1];

  const [downloadingId, setDownloadingId] = React.useState("");

  const loadingMessage = (): string => {
    switch (auctionType) {
      case AuctionType.tender:
        return t("tender.loading")
      case AuctionType.price_offer:
        return t("price-offer.loading")
      case AuctionType.single_source:
        return t("single-source.loading")
      default:
        return t("auction.loading")
    }
  }

  const detailsErrorMessage = (): string => {
    switch (auctionType) {
      case AuctionType.tender:
        return t("snack.error.tender_details")
      case AuctionType.price_offer:
        return t("snack.error.price-offer_details")
      case AuctionType.single_source:
        return t("snack.error.single-source_details")
      default:
        return t("snack.error.auction_details")
    }
  }

  const downloadFile = async (file: AuctionFile) => {
    const errorText = "Произошла ошибка при загрузке файла";

    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await publicService.getFileToken(auctionId, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.title);
      }
      else { snack(errorText, "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(errorText, "error");
    }
    finally { setDownloadingId("") }
  };

  const onClose = () => navigate(`/account-${auctionPath}`);
  const onEdit = () => {
    setAuctionEditing && setAuctionEditing(true);
    setAuction(null)
  };

  const auctionOrTender = auction?.auctionType === AuctionType.auction || auction?.auctionType === AuctionType.tender;

  {/* SIGNING */}
    const storageType = useDictionary(
      StorageType,
      (type) => ({value: type, label: t(`storage_type.${type}`)}),
      { value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
    );

    const { signXML } = useSignature();

    const [isSigning, setIsSigning] = useState(false);
    const [signingError, setSigningError] = useState("");
    async function onSign() {
      if (auction && currentDate) {
        if (auctionOrTender && currentDate.getTime() > new Date(auction.registrationOpenTime).getTime()) {
          const errorText = t("auction.errors.registrationTime");

          setSigningError(errorText);
          snack(errorText, "error");
          return
        }

        try {
          if (!company || !storageType.selected) return;
          setIsSigning(true);

          const response = await companyService.getPassport(company.id, auctionId);
          if (response.status !== "success" || !response.data) {
            snack(t("snack.error.auction_sign_passport"), "error");
            return
          }

          const passportXml = b64DecodeUnicode(response.data.passport);
          const signedXml = await signXML(passportXml, storageType.selected.value as StorageType);

          setLoading(true);
          try {
            if (!signedXml) return;

            const response = await auctionService.signAuction(company.id, auctionId, b64EncodeUnicode(signedXml));
            if (response.status === "success" && response.data) {
              snack(t("snack.success.auction_signed"), "success");
              onClose();
            }
            else { snack(t("snack.error.auction_signing"), "error"); }
          }
          catch (error) {
            const err = error as AxiosError;
            snack(t("snack.error.auction_signing"), "error")
          }
          finally { setLoading(false) }
        }
        catch (error) {
          console.error("ERROR", error);

          if (error === "NO_NCALAYER") {
            snack(t("snack.error.no_ncalayer"), "error")
          }
          else if (error === "storage.empty") { snack(t("snack.error.storage_empty"), "error") }
        }
        finally { setIsSigning(false) }
      }
    };
  {/* / SIGNING */}

  return (
    <Box>
      {loading
        ? <Box>
            <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
            <Typography>{loadingMessage()}</Typography>
          </Box>
        : !auction
            ? <Box>
                <Typography>{detailsErrorMessage()}</Typography>
              </Box>
            : <Grid container rowSpacing={3}>
                {auction.reviews
                  ? <>
                      <Reviews reviews={auction.reviews} />
                      <Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
                    </>
                  : null
                }

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.dictionary.state")}
                      value={t(`auction_state.${auction.state}`)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.participantCount")}
                      value={auction.participantCount}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.bidCount")}
                      value={auction.bidCount}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("auction.sections.customer")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.companyTitle")}
                      value={auction.company.title}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.companyItn")}
                      value={digitsOnly(auction.company.itn)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.supervisor")}
                      value={auction.supervisor.name}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                {/* карточка заявки, организация является участником */}
                {company?.itn === auction?.participant?.company.itn
                  ? <>
                      <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <Typography variant="h6">
                            {t("auction.sections.contest")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.companyTitle")}
                            value={auction?.participant?.company.title}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.companyItn")}
                            value={digitsOnly(auction?.participant?.company.itn)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.supervisor")}
                            value={auction?.participant?.company.ceoName}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>

                      <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />
                    </>
                  : null
                }

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("auction.sections.contest")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.auctionTitle")}
                      value={auction.title}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.dictionary.auctionType")}
                      value={t(`auction_type.${auction.auctionType}`)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12} sx={{ mt: 1 }}>
                    <TextField multiline minRows={4}
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.auctionDescription")}
                      value={auction.description}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                {auction.auctionType === AuctionType.auction
                  ? <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth color="primary" type="text" size="small"
                          label={t("auction.dictionary.auctionCategory")}
                          value={t(`auction_category.${auction.auctionCategory}`)}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth color="primary" type="text" size="small"
                          label={t("auction.dictionary.bidStep")}
                          value={t(`bid_step.${auction.bidStep}`)}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth color="primary" type="text" size="small"
                          label={t("auction.dictionary.bidDirection")}
                          value={t(`bid_direction.${auction.bidDirection}`)}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  : null
                }

                <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                <Rubric auction={auction} />

                <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("auction.sections.dates")}
                    </Typography>
                  </Grid>
                  {auctionOrTender
                    ? <>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.registrationOpenTime")}
                            value={auction.registrationOpenTime.replace("T", " ")}
                            InputProps={{ readOnly: true }}
                            helperText={signingError} error={signingError ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.registrationCloseTime")}
                            value={auction.registrationCloseTime.replace("T", " ")}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </>
                    : null
                  }
                </Grid>

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.bidOpenTime")}
                      value={auction.bidOpenTime.replace("T", " ")}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.bidCloseTime")}
                      value={auction.bidCloseTime.replace("T", " ")}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                {auctionOrTender
                  ? <>
                      <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />
                      <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <Typography fontSize={12}>
                            {t("auction.fields.aboutApprovementTime")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.approvementOpenTime")}
                            value={auction.registrationCloseTime.replace("T", " ")}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.approvementCloseTime")}
                            value={auction.bidOpenTime.replace("T", " ")}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  : null
                }

                <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t("auction.sections.docs")}
                    </Typography>
                  </Grid>

                  <Grid item container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
                    {auction.attachments.map(file => (
                      <Grid item key={file.id}>
                        <Typography fontSize={12} textAlign={"center"} mt={1} mb={.5}>
                          {t(`file_category.${file.category}`)}
                        </Typography>

                        <Chip label={file.title}
                          onClick={() => downloadFile(file)}
                          disabled={downloadingId === file.id}
                        />

                        {downloadingId === file.id && <LinearProgress color="primary" />}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
      }

      <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

      <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
        <Grid item xs="auto">
          <Button fullWidth variant="contained" size="large"
                  onClick={onClose} color="secondary"
          >
            {t("auction.close")}
          </Button>
        </Grid>
        {(auction && setAuctionEditing) // auction auction check
          ? auction?.state === "draft"
            ? <>
                <Grid item xs="auto">
                  <Button fullWidth variant="contained" size="large"
                          onClick={onEdit} color={signingError ? "primary" : "info"}
                  >
                    {t("auction.edit")}
                  </Button>
                </Grid>

                <SignatureBtn
                  onSign={onSign}
                  isSigning={isSigning}
                  signingError={signingError}
                  storageType={storageType}
                />
              </>
            : null
          : null
        }
      </Grid>
		</Box>
  )
}
