import { Grid, Typography, TextField } from "@mui/material";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { PhoneMaskInputComponent } from "../../../../../common/NumberFormat";
import { NewCompanyCEOForm } from "../../../../../types/newCompany";
import digitsOnly from "../../../../../utils/digitsOnly";
import { initialValues, ValidationSchema } from "./formik";

export function CEOFields({
  formikRef
}: {
  formikRef: RefObject<FormikProps<NewCompanyCEOForm>>;
}) {
  const onSubmit = (values: NewCompanyCEOForm) => {};
  const { t } = useTranslation();

  return (
    <Formik innerRef={formikRef}
      initialValues={initialValues} 
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewCompanyCEOForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h6">
              {t("cabinet.company_details.ceo")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name={"ceoPhone"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" size="small"
                  label={t("auction.fields.company.ceo_phone")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                  InputProps={{ inputComponent: PhoneMaskInputComponent }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name={"ceoName"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.ceo_name")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}