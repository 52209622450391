import { Button, Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { FormikProps, Formik, Field, FieldProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { companyService } from "../../../../../api/services/company";
import { CompanyExists, NewAuctionParticipantForm } from "../../../../../types/newAuction";
import { useSnack } from "../../../../../utils/useSnack";
import { initialValues, ValidationSchema } from "./formik";

export function ParticipantFields({
  formikRef, editingInitialState
}: {
  formikRef: React.RefObject<FormikProps<NewAuctionParticipantForm>>;
  editingInitialState?: NewAuctionParticipantForm
}) {
  function onSubmit(values: NewAuctionParticipantForm) {};
  const { snack } = useSnack();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [participantExists, setParticipantExitsts] = useState<CompanyExists | null>(null);

  async function searchCompany() {
    const values = formikRef.current?.values;
    if (!values?.participantItn) {
      snack(t("validation.itn_required"), "warning");
      return
    }

    try {
      setLoading(true);
      setParticipantExitsts(null);
      formikRef.current?.setFieldValue("participantId", "");

      const response = await companyService.checkCompany(values.participantItn);
      if (response.status === "success" && response.data) {
        if (!response.data.exists) {
          snack(t("snack.warning.no_company_with_this_itn"), "warning");
          return
        }
        
        setParticipantExitsts(response.data);
        formikRef.current?.setFieldValue("participantId", response.data.id);
      }
      else { snack(t("snack.error.search_company"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.search_company"), "error");
    }
    finally { setLoading(false) }
  }

  return (
    <Formik innerRef={formikRef}
      initialValues={editingInitialState || initialValues} 
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewAuctionParticipantForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h6">
              {t("auction.sections.participant")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name={"participantItn"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.participant_itn")}
                  error={(meta.touched && meta.error)
                    ? true
                    : false
                  }
                  helperText={(meta.touched && meta.error)
                    ? meta.error
                    : ""
                  }
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Button color="primary" variant="contained" onClick={searchCompany}
              disabled={loading}
            >
              {loading
                ? t("auction.participant.searching_company_by_itn")
                : t("auction.participant.search_company_by_itn")
              }
              
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name={"participantId"}>
              {({ field, form, meta }: FieldProps) => (
                <Typography color={participantExists?.exists ? "primary" : "error"}>
                  {participantExists?.exists
                    ? participantExists.shortTitle
                    : meta.error
                        ? meta.error
                        : ""
                  }
                </Typography>
              )}
            </Field>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}