import React, { useState } from "react";
import { Autocomplete, Box, Button, Grid, LinearProgress, Modal, 
  TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import { useSnack } from "../../../../utils/useSnack";
import { moderatorService } from "../../../../api/services/moderator";
import { StandardReviewReason, StorageType } from "../../../../utils/enums";
import { useDictionary } from "../../../../utils/useDictionary";
import SignatureBtn from "../../../../common/SignatureBtn";
import useSignature from "../../../../api/services/signature";
import { useSelector } from "react-redux";
import { State } from "../../../../redux/store";
import { b64EncodeUnicode } from "../../../../utils/base64";
import { modalStyle } from "../../../../common/modalStyle";

export const TerminateModal = ({
  isOpen, onClose, auctionId, onAuctionClose
}: {
  isOpen: boolean;
  onClose: () => void;
  auctionId: string;
  onAuctionClose: () => void;
}) => {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);

  {/* SIGNING */}
    const [signature, setSignature] = useState("");
    const storageType = useDictionary(
      StorageType, 
      (type) => ({value: type, label: t(`storage_type.${type}`)}),
      { value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
    );
    const { signXML } = useSignature();
    const [isSigning, setIsSigning] = useState(false);
    const [signingError, setSigningError] = useState("");
    async function onSign() {
      if (!reviewReason.selected?.value || !notes) {
        snack(t("snack.warning.terminate_reason"), "warning");
        return
      }

      try {
        if (!state.auth.user || !storageType.selected) return;
        setIsSigning(true);

        const terminateDetails = {
          documentType: t("snack.warning.terminate_by_moderator"),
          auctionId: auctionId,
          author: state.auth.user.name,
        }
        const terminateDetailsXml = `<terminate>${b64EncodeUnicode(JSON.stringify(terminateDetails))}</terminate>`
        const signedXml = await signXML(terminateDetailsXml, storageType.selected.value as StorageType);
        setSignature(signedXml);
        snack(t("snack.success.signed_confirm"), "success");
      }
      catch (error) {
        console.error("ERROR", error);

        if (error === "NO_NCALAYER") {
          snack(t("snack.error.no_ncalayer"), "error")
        } else if (error === "storage.empty") { 
          snack(t("snack.error.storage_empty"), "error") 
        }
      }
      finally { setIsSigning(false) }
    };
  {/* / SIGNING */}

  const reviewReason = useDictionary(
    StandardReviewReason, 
    (type) => ({value: type, label: t(`review_reason.${type}`)}),
    { value: StandardReviewReason.rule_violated, label: t("review_reason.rule_violated") }
  );

  const [notes, setNotes] = React.useState("");
  
  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => { setState(e.target.value) };

  const [isLoading, setIsLoading] = React.useState(false);
  const terminateAuction = async () => {
    if (reviewReason.selected?.value && notes) {
      const data = { 
        standardReason: reviewReason.selected?.value as StandardReviewReason, 
        notes, 
        signature: b64EncodeUnicode(signature)
      };

      try {
        setIsLoading(true);
        const result = await moderatorService.terminateAuction(auctionId, data);
        if (result.status === "success") {
          snack(t("snack.success.auction_terminated"), "success"); 
        }
        else { snack(t("snack.success.auction_terminate"), "error") }
        onAuctionClose();
      }
      catch (error) {
        const err = error as AxiosError;
        snack(t("snack.success.auction_terminate"), "error"); 
        onCancel()
      }
      finally { setIsLoading(false) }
    }
    else { snack(t("snack.warning.terminate_reason"), "warning") }
  };

  const onCancel = () => { setNotes(""); onClose() };
  
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("auction.modals.terminate.reason")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.modals.terminate.terminating")}</Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <Autocomplete
                    disablePortal size="small"
                    options={reviewReason.dictionary}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    value={reviewReason.selected} 
                    onChange={reviewReason.onSelectChange}
                    renderInput={params => 
                      <TextField {...params} fullWidth color="primary" 
                                name="reviewReason" 
                                label={t("auction.fields.moderator.review_reason")}
                      /> 
                    } 
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    name="notes" 
                    label={t("auction.fields.moderator.notes")}
                    value={notes}
                    onChange={e => handleTextChange(e, setNotes)}
                  />
                </Grid>

                <SignatureBtn 
                  onSign={onSign} 
                  isSigning={isSigning} 
                  signingError={signingError} 
                  storageType={storageType} 
                  signed={signature ? true : false}
                />
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("auction.modals.common.cancel")}
          </Button>

          <Button variant="contained" color="primary" size="small"
                  onClick={terminateAuction} disabled={!signature || isLoading}
          >
            {t("auction.modals.terminate.confirm_termination")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
