import { useSnackbar } from "notistack";

export function useSnack() {
  const { enqueueSnackbar } = useSnackbar();

  function snack(message: string, variant: "success" | "error" | "info" | "warning") {
    return enqueueSnackbar(message, { variant });
  }

  return { snack }
}