import {t} from "i18next";
import * as Yup from "yup";
import {NewAuctionDatesForm} from "../../../../../types/newAuction";

export const initialValues: NewAuctionDatesForm = {
    registrationOpenTime: null,
    registrationCloseTime: null,
    bidOpenTime: null,
    bidCloseTime: null
};

export function AuctionOrTenderValidationSchema(
    currentDate: Date
) {
    return Yup.object({
        registrationOpenTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime"))
            .min(currentDate, t("validation.registrationOpenTime")),
        registrationCloseTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime"))
            .min(Yup.ref("registrationOpenTime"), t("validation.registrationCloseTime")),
        bidOpenTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime"))
            .min(Yup.ref("registrationCloseTime"), t("validation.bidOpenTime")),
        bidCloseTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime")),
    })
}

export function PriceOfferOrSingleSourceValidationSchema() {
    return Yup.object({
        bidOpenTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime"))
            .min(Yup.ref("registrationCloseTime"), t("validation.bidOpenTime")),
        bidCloseTime: Yup.date()
            .typeError(t("validation.requiredDateAndTime")),
    })
}
