import React, { useState } from "react";
import { Box, Button, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import AnimateHeight from "react-animate-height";
import { useSnack } from "../../../../utils/useSnack";
import { moderatorService } from "../../../../api/services/moderator";
import { ReviewResult, StorageType } from "../../../../utils/enums";
import { State } from "../../../../redux/store";
import { t } from "i18next";
import { useSelector } from "react-redux";
import useSignature from "../../../../api/services/signature";
import SignatureBtn from "../../../../common/SignatureBtn";
import { b64EncodeUnicode } from "../../../../utils/base64";
import { useDictionary } from "../../../../utils/useDictionary";
import { modalStyle } from "../../../../common/modalStyle";

export const AcceptModal = ({
  isOpen, onClose, auctionId, onAuctionClose
}: {
  isOpen: boolean;
  onClose: () => void;
  auctionId: string;
  onAuctionClose: () => void;
}) => {
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);

  {/* SIGNING */}
    const [signature, setSignature] = useState("");
    const storageType = useDictionary(
      StorageType, 
      (type) => ({value: type, label: t(`storage_type.${type}`)}),
      { value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
    );
    const { signXML } = useSignature();
    const [isSigning, setIsSigning] = useState(false);
    const [signingError, setSigningError] = useState("");
    async function onSign() {
      try {
        if (!state.auth.user || !storageType.selected) return;
        setIsSigning(true);

        const declineDetails = {
          documentType: t("auction.modals.accept.accept_by_moderator"),
          auctionId: auctionId,
          author: state.auth.user.name,
        }
        const declineDetailsXml = `<accept>${b64EncodeUnicode(JSON.stringify(declineDetails))}</accept>`
        const signedXml = await signXML(declineDetailsXml, storageType.selected.value as StorageType);
        setSignature(signedXml);
        snack(t("snack.success.signed_confirm"), "success");
      }
      catch (error) {
        console.error("ERROR", error);

        if (error === "NO_NCALAYER") {
          snack(t("snack.error.no_ncalayer"), "error")
        } else if (error === "storage.empty") {
          snack(t("snack.error.storage_empty"), "error") 
        }
      }
      finally { setIsSigning(false) }
    };
  {/* / SIGNING */}

  const [isLoading, setIsLoading] = React.useState(false);
  const acceptAuction = async () => {
    const data = { 
      result: ReviewResult.accepted,
      declineReason: "", 
      signature: b64EncodeUnicode(signature)
    };

    try {
      setIsLoading(true);
      const result = await moderatorService.reviewAuction(auctionId, data);
      if (result.status === "success") {
        snack(t("snack.success.auction_accepted"), "success"); 
      }
      else { snack(t("snack.error.auction_accept"), "error") }
      onAuctionClose();
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.auction_accept"), "error"); 
      onCancel()
    }
    finally { setIsLoading(false) }
  };

  const onCancel = () => { onClose() };
  
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("auction.modals.accept.title")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.modals.accept.accepting")}</Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <Typography>{t("auction.modals.accept.are_you_sure")}</Typography>
                </Grid>

                <SignatureBtn 
                  onSign={onSign} 
                  isSigning={isSigning} 
                  signingError={signingError} 
                  storageType={storageType} 
                  signed={signature ? true : false}
                />
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("auction.modals.common.cancel")}
          </Button>
          <Button variant="contained" color="primary" size="small"
                  onClick={acceptAuction} disabled={!signature || isLoading}
          >
            {t("auction.modals.accept.confirm_accept")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
