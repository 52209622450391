import { SelectChangeEvent } from "@mui/material";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { companyService } from "../api/services/company";
import { SET_COMPANIES, SET_COMPANIES_LOADING, SET_COMPANY, SET_NO_COMPANIES } from "../redux/reducers/company";
import { State } from "../redux/store";
import { Company } from "../types/company";
import { getLSCompanyIdx, setLSCompanyIdx } from "./company";
import { useSnack } from "./useSnack";

export function useCompany() {
  const { snack } = useSnack();
  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const dispatch = useDispatch();
  const state = useSelector((state: State) => state);
  const companiesLoading: boolean = state.company.loading;
  const companies: Company[] = state.company.companies;
  const noCompanies: boolean = state.company.noCompanies;
  const company: Company | null = state.company.company;

  const [companyIdx, setCompanyIdx] = useState(getLSCompanyIdx());
  function onCompanyChange(e: SelectChangeEvent) {
    setCompanyIdx(+e.target.value);
    setLSCompanyIdx(+e.target.value);
    const company = selectCompany(+e.target.value);

    if (pathArray.length > 2) navigate(`${pathArray[0]}/${pathArray[1]}`);

    return company
  }

  async function getCompanies() {
    dispatch({ type: SET_COMPANIES_LOADING, payload: true });
    try {
      const response = await companyService.getCompanies();

      if (response.status === "success" && response.data) {
        if (!response.data.length) {
          dispatch({ type: SET_NO_COMPANIES, payload: true });
          return;
        }

        dispatch({ type: SET_COMPANIES, payload: response.data });
        dispatch({ type: SET_COMPANIES_LOADING, payload: false });
      }
      else { snack("Произошла ошибка при загрузке организаций", "error"); }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response)
    }
  }

  function selectCompany(companyIdx: number) {
    const company = companies[companyIdx];
    if (!company) snack("Произошла ошибка", "error");
    dispatch({ type: SET_COMPANY, payload: company });
    return company
  }

  return { 
    companiesLoading, companies, getCompanies, 
    selectCompany, companyIdx, company, onCompanyChange, 
    noCompanies 
  }
}