import {t} from "i18next";
import * as Yup from "yup";
import { NewAuctionCustomerForm } from "../../../../../types/newAuction";

export const initialValues: NewAuctionCustomerForm = {
  supervisor: {
    userId: "",
    username: "",
    name: "",
    identity: "",
    role: "",
  },
};

export function ValidationSchema() {
  return Yup.object({
    supervisor: Yup.object({
      userId: Yup.string().required(t("validation.required")),
    }),
  })
}
