import React, { useState } from "react";
import { Box, Button, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import AnimateHeight from "react-animate-height";
import { useSnack } from "../../../../utils/useSnack";
import { State } from "../../../../redux/store";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { companyService } from "../../../../api/services/company";
import { Company, CompanyFile } from "../../../../types/company";
import { modalStyle } from "../../../../common/modalStyle";

export const DeleteFileModal = ({
  isOpen, onClose, getFiles, fileId
}: {
  isOpen: boolean;
  onClose: () => void;
  getFiles: () => Promise<void>;
  fileId: string;
}) => {
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;

  const [isCannotDelete, setIsCannotDelete] = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const deleteFile = async () => {
    if (!company) return;
    
    try {
      setIsLoading(true);
      const response = await companyService.deleteFile(
        company.id, fileId, isCannotDelete ? "archive" : "delete"
      );
      if (response.status === "success") {
        snack(
          isCannotDelete 
            ? t("snack.success.docs_archived") 
            : t("snack.success.docs_archived"),
          "success"
        );
        getFiles();
        onClose();
      }
      else if (response.code === "file_was_used") {
        setIsCannotDelete(true);
        snack(t("snack.error.file_was_used"), "error"); 
      }
      else { snack(t("snack.error.default"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.delete_file"), "error"); 
    }
    finally { setIsLoading(false); }
  };

  const onCancel = () => { onClose(); setIsCannotDelete(false); };
  
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" 
                sx={{ fontSize: { xs: 14, sm: 20 } }}
              >
                {t("docs.deletion")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>
                  {isCannotDelete
                    ? t("docs.archiving")
                    : t("docs.deletion") + "..."
                  }
                  </Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <Typography>
                    {isCannotDelete
                      ? t("docs.cannot_delete_can_archive")
                      : t("docs.sure_you_wanna_delete")
                    }
                    </Typography>
                </Grid>
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("docs.cancel")}
          </Button>
          <Button variant="contained" color="primary" size="small"
                  onClick={deleteFile} disabled={isLoading || isCannotDelete}
          >
            {t("docs.confirm_deletion")}
          </Button>
          {isCannotDelete
            ? <Button variant="contained" color="primary" size="small"
                      onClick={deleteFile} disabled={isLoading} sx={{ ml: 1 }}
              >
                {t("docs.archive")}
              </Button>
            : null
          }
        </Grid>
      </Box>
    </Modal>
  )
}
