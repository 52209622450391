import { AnyAction } from "redux"

export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";
export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT";

const initialState = {
  announcement: null,
}

export const announcementReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ANNOUNCEMENT: return { ...state,
      announcement: action.payload
    }

    case CLEAR_ANNOUNCEMENT: return { ...state,
      announcement: initialState.announcement
    }

    default: return state
  }
}
