import { Box, IconButton, ListItem, Menu, MenuItem, Typography } from "@mui/material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useState } from "react";
import { onLogout } from "../utils/auth";
import { useSelector } from "react-redux";
import { State } from "../redux/store";
import { User } from "../types/auth";
import { useTranslation } from "react-i18next";

export function ProfileMenu({ themeMode }: { themeMode: "light" | "dark" }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() { setAnchorEl(null) }

  const { t } = useTranslation();
  const state = useSelector((state: State) => state);
  const user: User | null = state.auth.user;
  const role = user?.authorities[user?.authorities.length - 1];

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        color={themeMode === 'light' ? 'primary' : 'secondary'}
      >
        <AccountBoxIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ListItem sx={{ display: "block" }}>
          <Typography fontSize={12} component="p">{role ? t(`authority.${role}`) : "- - -"}</Typography>
          <Typography>{user?.username}</Typography>
        </ListItem>
        <MenuItem component='a' href="/" onClick={() => { handleClose(); onLogout() }}>
          {t("cabinet.logout")}
        </MenuItem>
      </Menu>
    </Box>
  )
}