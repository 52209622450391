import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Auction } from "../../../../types/auction";
import { Company } from "../../../../types/company";
import { auctionService } from "../../../../api/services/auction";
import { companyService } from "../../../../api/services/company";
import { State } from "../../../../redux/store";
import { CompanyFile } from "../../../../types/company";
import digitsOnly from "../../../../utils/digitsOnly";
import { AuctionState, StorageType } from "../../../../utils/enums";
import { publicService } from "../../../../api/services/public";
import { useSnack } from "../../../../utils/useSnack";
import { ArchivedFilesModal } from "../../../../common/FilesModals/Archived";
import { NewFileModal } from "../../../../common/FilesModals/New";
import { useDictionary } from "../../../../utils/useDictionary";
import useSignature from "../../../../api/services/signature";
import { b64EncodeUnicode } from "../../../../utils/base64";
import SignatureBtn from "../../../../common/SignatureBtn";
import {Participant} from "../../../../types/participant";

export default function Participate({ auctionId, auction, getAuction }: {
  auctionId: string;
  auction: Auction | null;
  getAuction: () => Promise<void>
}) {
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;
  const { t } = useTranslation();
  const { snack } = useSnack();
  const navigate = useNavigate();
  const currentDate: Date | null = state.public.currentTime;

  const [attachments, setAttachments] = useState<CompanyFile[]>([]);
  const [signature, setSignature] = useState("");
  const [sending, setSending] = useState(false);

  const pickFile = (file: CompanyFile) => {
    if (attachments.filter(item => item.id === file.id).length === 0) {
      setAttachments(prev => [...prev, file]);
      snack(t("snack.success.document_attached"), "success")
    }
    else { snack(t("snack.info.document_already_attached"), "info") }
  };
  const removeFile = (fileId: string) => {
    setAttachments(prev => prev.filter(file => file.id !== fileId));
  };

  const [downloadingId, setDownloadingId] =  useState("");
  const downloadFile = async (file: CompanyFile) => {
    if (!company) { return }

    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await companyService.getFileToken(company.id, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.name);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.default"), "error"); 
    }
    finally { setDownloadingId("") }
  }

  const [isArchivedOpen, setIsArchivedOpen] = useState(false);
  const onOpenArchived = () => setIsArchivedOpen(true);
  const onCloseArchived = () => setIsArchivedOpen(false);
  
  const [isNewOpen, setIsNewOpen] = useState(false);
  const onOpenNew = () => setIsNewOpen(true);
  const onCloseNew = () => setIsNewOpen(false);

  const [isParticipant, setIsParticipant] = useState(!!auction?.participants?.find((participant: Participant) => participant.company.id === company?.id ))

  async function onParticipate() {
    if (!company) { return }

    const data = {
      companyId: company.id,
      attachments: attachments.map(file => file.id),
      signature: b64EncodeUnicode(signature)
    }
    try {
      setSending(true);
      const response = await auctionService.participate(auctionId, data);
      if (response.status === "success" && response.data) {
        snack(t("snack.success.participate"), "success");
        setIsParticipant(true)
        navigate("/account-auctions-participated");
        return
      }
      snack(t("snack.error.participate"), "error"); 
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.participate"), "error"); 
    }
    finally { setSending(false) }
  }

  {/* SIGNING */}
    const storageType = useDictionary(
      StorageType, 
      (type) => ({value: type, label: t(`storage_type.${type}`)}),
      { value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
    );

    const { signXML } = useSignature();

    const [isSigning, setIsSigning] = useState(false);
    const [signingError, setSigningError] = useState("");

    async function onSign() {
      try {
        if (!company || !storageType.selected || !auction) return;
        setIsSigning(true);

        const signDetails = {
          documentType: t("auction.participate.documentType"),
          auctionNo: auction.auctionNo,
          companyItn: company.itn,
        }
        const signDetailsXml = `<participate>${b64EncodeUnicode(JSON.stringify(signDetails))}</participate>`
        const signedXml = await signXML(signDetailsXml, storageType.selected.value as StorageType);
        setSignature(signedXml);
        snack(t("snack.success.participate_signed"), "success");
      }
      catch (error) {
        console.error("ERROR", error);

        if (error === "NO_NCALAYER") {
          snack(t("snack.error.no_ncalayer"), "error")
        }
        else if (error === "storage.empty") { 
          snack(t("snack.error.storage_empty"), "error") 
        }
      }
      finally { setIsSigning(false) }
    }
  {/* / SIGNING */}

  return (
    <Box>
      <Grid item xs={12} sx={{ mb: 7 }}>
        <Typography fontSize={20} sx={{ mb: 1 }}>
          {auction?.state === AuctionState.published
            ? `${t("auction.fields.registrationOpenTime")}: ${auction?.registrationOpenTime.replace('T', " ")}`
            : `${t("auction.fields.registrationCloseTime")}: ${auction?.registrationCloseTime.replace('T', " ")}`
          }
        </Typography>
        <Typography fontSize={20}>
          {t("auction.participate.auctionState")}: {t(`auction_state.${auction?.state}`)}
        </Typography>
      </Grid>

      {auction?.state === AuctionState.registration
        ? <>
            <Grid container rowSpacing={3}>
              <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Typography variant="h6">
                    {t("auction.participate.companyParticipant")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.companyTitle")}
                    value={company?.title}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.companyItn")}
                    value={digitsOnly(company?.itn)}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.ceoName")}
                    value={company?.ceoName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />

              <Grid container columnSpacing={3} rowSpacing={1}>
                <ArchivedFilesModal
                  isOpen={isArchivedOpen} onClose={onCloseArchived} 
                  pickFile={pickFile} downloadFile={downloadFile}
                  downloadingId={downloadingId} onOpenNew={onOpenNew}
                />
                <NewFileModal
                  isOpen={isNewOpen} onClose={onCloseNew} 
                  pickFile={pickFile}
                />

                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Typography variant="h6">
                    {t("auction.participate.attached_docs")}
                  </Typography>
                </Grid>

                { !isParticipant &&
                    <Grid item>
                      <Grid item container columnSpacing={3} rowSpacing={1}>
                        <Grid item>
                          <Button color={"primary"} variant="contained" size="small" onClick={onOpenArchived}>
                            {t("auction.participate.add_archived")}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button color={"primary"} variant="contained" size="small" onClick={onOpenNew}>
                            {t("auction.participate.add_new")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                }
                <Grid item container columnSpacing={2} rowSpacing={1}>
                  {attachments.map(file => {
                    return (
                      <Grid item key={file.id}>
                        <Typography fontSize={12} textAlign={"center"} mt={1} mb={.5}>
                          {t(`file_category.${file.category}`)}
                        </Typography>

                        <Chip key={file.id}
                          label={file.title || file.name}
                          onClick={() => downloadFile(file)}
                          disabled={downloadingId === file.id}
                          onDelete={() => removeFile(file.id)}
                        />

                        {downloadingId === file.id &&
                          <LinearProgress color="primary" />          
                        }
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

            {sending
              ? <Box>
                  <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                  <Typography>
                    {t("auction.participate.sending_participation")}
                  </Typography>
                </Box>
              : <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
                  {signature
                    ? <Grid item xs="auto">
                        <Button fullWidth variant="contained" size="large" color="primary" disabled={true}>
                          {t("auction.participate.signed")}
                        </Button>
                      </Grid>
                      
                    : !isParticipant && <SignatureBtn
                        onSign={onSign}
                        isSigning={isSigning}
                        signingError={signingError}
                        storageType={storageType}
                      />
                  }

                  { !isParticipant &&
                    <Grid item xs="auto">
                      <Button fullWidth variant="contained" size="large" color="primary"
                              onClick={onParticipate} disabled={!signature}
                      >
                        {t("auction.participate.do_participate")}
                      </Button>
                    </Grid>
                  }
                </Grid>
            }
            
          </>
        : <Box>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h6">
                {auction?.state === AuctionState.published
                  ? t("auction.participate.participate_not_started")
                  : t("auction.participate.participate_ended")
                }
              </Typography>
            </Grid>
          </Box>
      }
    </Box>
  )
}
