import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import WebbeeLogo from '../../../../svg/logos/Webbee';
import { useSelector } from "react-redux";
import { State } from '../../../../redux/store';
import { dateStringToTime } from '../../../../utils/dates';
import { LinearProgress, Typography } from '@mui/material';
import { User } from '../../../../types/auth';
import { Authority } from '../../../../utils/enums';
import { CompaniesSelect } from '../../../../common/CompaniesSelect';
import { ProfileMenu } from '../../../../common/ProfileMenu';
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";

const Topbar = ({
  themeMode,
  themeToggler,
  setThemePalette,
  onSidebarOpen,
  paletteType,
}: {
  themeMode: "light" | "dark";
  themeToggler: () => void;
  setThemePalette: (type?: string) => void;
  onSidebarOpen: () => void;
  paletteType: string;
}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation();
  const state = useSelector((state: State) => state);
  const time = dateStringToTime(state.public.currentTime);
  const user: User | null = state.auth.user;
  const isUser = user && user.authorities[user.authorities.length - 1] === Authority.USER;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box marginRight={{ xs: 1, sm: 2 }} sx={{ display: { md: 'none', sm: 'block' } }}>
          <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          href="/"
          title="bidhub"
          height={{ xs: 28, md: 32 }}
          width={45}
        >
          <WebbeeLogo width="100%" height="100%" /> 
        </Box>
      </Box>

      {isUser
        ? <CompaniesSelect themeMode={themeMode} isUser={isUser} display={{ md: "flex", sm: "none", xs: "none" }} />
        : null
      }

      <Box display="flex" alignItems={'center'}>
        <Box
            width={{xs: 70}}
            textAlign="center"
        >
          <Link underline={i18n.language === "ru" ? "always" : "none"} style={{ cursor: 'pointer' }}
                component="a"
                onClick={() => i18n.changeLanguage("ru")}
          >
            RU
          </Link>
          &nbsp;
          <Link underline={i18n.language === "kk" ? "always" : "none"} style={{ cursor: 'pointer' }}
                component="a"
                onClick={() => i18n.changeLanguage("kk")}
          >
            KZ
          </Link>
        </Box>
        <Box
          width={{ xs: 70 }}
          textAlign="center"
        >
          {time
            ? <Typography color={themeMode === 'light' ? 'primary' : 'secondary'}>
                {time?.hours}:{time?.minutes}:{time?.seconds}
              </Typography>
            : <LinearProgress color={themeMode === 'light' ? 'primary' : 'secondary'} />
          }
        </Box>

        <Box>
          <IconButton
            onClick={() => themeToggler()}
            aria-label="Dark mode toggler"
            color={themeMode === 'light' ? 'primary' : 'secondary'}
          >
            {themeMode === 'light' ? (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                />
              </svg>
            ) : (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            )}
          </IconButton>
        </Box>

        <ProfileMenu themeMode={themeMode} />
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
