import React from "react";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { formValidation } from "../../../utils/formValidation";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { auctionService } from "../../../api/services/auction";
import { State } from "../../../redux/store";
import { NewAuctionCustomerForm, NewAuctionContestForm, NewAuctionRubricForm, NewAuctionTermsForm, NewAuctionForm, NewAuctionDatesForm, NewAuctionFilesForm, NewAuctionParticipantForm } from "../../../types/newAuction";
import { dateToString } from "../../../utils/dates";
import { AuctionCategory, AuctionType, BidStep, BidDirection, ProductType, Unit, DeliveryRegion, DeliveryCondition } from "../../../utils/enums";
import { CustomerFields, ContestFields, RubricFields, TermsFields, DatesFields, FilesFields, ParticipantFields } from "./components";
import { Company } from "../../../types/company";
import { Auction } from "../../../types/auction";
import { Response } from "../../../types/api";
import { useSnack } from "../../../utils/useSnack";

export default function NewAuction({
	title, auctionType, auctionPath, auctionEditing, setAuctionEditing
}: {
	title: string;
	auctionType: AuctionType;
	auctionPath: string;
	auctionEditing?: boolean;
	setAuctionEditing?: React.Dispatch<React.SetStateAction<boolean>>
}) {
	const { t } = useTranslation();
	const { snack } = useSnack();
	const navigate = useNavigate();
	const onClose = () => { 
		if (auctionEditing && setAuctionEditing) {
			setAuctionEditing(false);
		} 
		else { navigate(`/account-${auctionPath}`) }
	};
	const state = useSelector((state: State) => state);
	const company: Company = state.company.company;

	const customerFormikRef = React.useRef<FormikProps<NewAuctionCustomerForm>>(null);
	const contestFormikRef = React.useRef<FormikProps<NewAuctionContestForm>>(null);
	const rubricFormikRef = React.useRef<FormikProps<NewAuctionRubricForm>>(null);
	const termsFormikRef = React.useRef<FormikProps<NewAuctionTermsForm>>(null);
	const datesFormikRef = React.useRef<FormikProps<NewAuctionDatesForm>>(null);
	const filesFormikRef = React.useRef<FormikProps<NewAuctionFilesForm>>(null);
	const participantFormikRef = React.useRef<FormikProps<NewAuctionParticipantForm>>(null);

	const formikRefs = [
		customerFormikRef, 
		contestFormikRef,
		rubricFormikRef,
		termsFormikRef,
		datesFormikRef,
		filesFormikRef,
	];

	{/* EDITING */}
		const location = useLocation();
		const pathArray = location.pathname.split('/');
		const auctionId = auctionEditing ? pathArray[pathArray.length - 1] : "";
		const auction: Auction = state.auction.auction;
		const editingInitialState = auctionEditing ? {
			customer: { supervisor: { ...auction.supervisor, username: "", role: "" } },
			contest: {
				title: auction.title,
				auctionCategory: auctionType === AuctionType.auction 
					? { 
							value: auction.auctionCategory, 
							label: t(`auction_category.${auction.auctionCategory}`) 
						} 
					: null,
				auctionType: { 
					value: auction.auctionType, 
					label: t(`auction_type.${auction.auctionType}`) 
				},
				description: auction.description,
				bidStep: auctionType === AuctionType.auction 
					? { 
							value: auction.bidStep, 
							label: t(`bid_step.${auction.bidStep}`) 
						} 
					: null,
				bidDirection: auctionType === AuctionType.auction 
					? { 
							value: auction.bidDirection, 
							label: t(`bid_direction.${auction.bidDirection}`) 
						} 
					: null
			},
			rubric: {
				productType: { 
					value: auction.productType, 
					label: t(`product_type.${auction.productType}`) 
				},
				items: auction.items
			},
			terms: {
				deliveryCondition: { 
					value: auction.deliveryCondition, 
					label: t(`delivery_condition.${auction.deliveryCondition}`) 
				},
				deliveryTime: auction.deliveryTime
			},
			files: { 
				attachments: auction.attachments.map(item => {
					return { ...item, 
						state: "", name: "", description: "", used: true, created: ""
					}
				}) 
			},
			participant: {
				participantItn: auction.participant
					? auction.participant.company.itn
					: "",
				participantId: auction.participant
					? auction.participant.company.id
					: ""
			}
		} : undefined;
	{/* / EDITING */}

	const validateAll = () => formikRefs.forEach(ref => formValidation(ref));
	const allIsValid = () => formikRefs
		.map(ref => ref.current?.isValid)
		.every(item => item);

	const validateParticipant = () => auctionType === AuctionType.single_source 
		? formValidation(participantFormikRef)
		: true;
	const participantIsValid = () => auctionType === AuctionType.single_source 
		? participantFormikRef.current?.isValid ? true : false
		: true;

	const submit = async () => {
		if (!company) { return }

		const allRefsDefined = (
			customerFormikRef.current &&
			contestFormikRef.current &&
			rubricFormikRef.current &&
			termsFormikRef.current &&
			datesFormikRef.current &&
			filesFormikRef.current && 
			true
		);

		validateAll();
		validateParticipant();

		if (allRefsDefined && allIsValid() && participantIsValid()) {
			const customer = customerFormikRef.current.values;
			const contest = contestFormikRef.current.values;
			const rubric = rubricFormikRef.current.values;
			const terms = termsFormikRef.current.values;
			const dates = datesFormikRef.current.values;
			const files = filesFormikRef.current.values;
			const participant = participantFormikRef.current?.values;

			const data: NewAuctionForm = {
				supervisorId: customer.supervisor.userId,
				title: contest.title,
				auctionCategory: auctionType === AuctionType.auction ? contest.auctionCategory?.value as AuctionCategory : null,
				auctionType: contest.auctionType.value as AuctionType,
				description: contest.description,
				bidStep: auctionType === AuctionType.auction ? contest.bidStep?.value as BidStep : null,
				bidDirection: auctionType === AuctionType.auction ? contest.bidDirection?.value as BidDirection : null,
				productType: rubric.productType.value as ProductType,
				items: rubric.items.map(item => ({ ...item, 
					unit: item.unit.value as Unit,
					deliveryRegion: item.deliveryRegion.value as DeliveryRegion,
					price: item.price.toString().includes(" ") ? item.price.replace(/\s/g, "") : item.price,
					amount: item.amount.toString().includes(" ") ? item.amount.replace(/\s/g, "") : item.amount
				})),
				deliveryCondition: terms.deliveryCondition.value as DeliveryCondition,
				deliveryTime: terms.deliveryTime,
				registrationOpenTime: dateToString(dates.registrationOpenTime) as string,
				registrationCloseTime: dateToString(dates.registrationCloseTime) as string,
				bidOpenTime: dateToString(dates.bidOpenTime) as string,
				bidCloseTime: dateToString(dates.bidCloseTime) as string,
				attachments: files.attachments.map(file => file.id),
				participantId: participant ? participant.participantId : null
			};


			try {
				let response: Response<Auction>;
				if (auctionEditing) { 
					response = await auctionService.editAuction(company.id, auctionId, data) 
				}
				else { response = await auctionService.createAuction(company.id, data) }

				if (response && response.data && response.status === "success") {
					snack(
						auctionEditing 
							? t("snack.success.auction_edited")
							: t("snack.success.auction_created"),
						"success"
					); 
					navigate(`/account-${auctionPath}/${response.data.id}`);
					if (setAuctionEditing) setAuctionEditing(false);
				}
				else { snack(t("snack.error.default"), "error") }
			}
			catch (error) {
				const err = error as AxiosError;
				console.log(err.response);
				snack(t("snack.error.default"), "error"); 
			}
		}
		else { snack(t("snack.warning.required_fields"), "warning"); }
	};

	return (
		<Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
			<Grid container spacing={3} justifyContent="space-between">
				<Grid item>
					<Typography component="h5" variant="h5" 
											sx={{ fontWeight: 700 }}>
						{auctionEditing 
							? `${title} №${auction.auctionNo}` 
							: title
						}
					</Typography>
				</Grid>
			</Grid>

			<Divider sx={{ mt: 2, mb: 6, width: "100%" }} />

			<Grid container rowSpacing={3}>
				<CustomerFields formikRef={customerFormikRef} 
					editingInitialState={editingInitialState?.customer} />

				{auctionType === AuctionType.single_source
					? <>
							<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
							<ParticipantFields formikRef={participantFormikRef} 
								editingInitialState={editingInitialState?.participant} />
						</>
					: null
				}

					<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
				<ContestFields formikRef={contestFormikRef} currentAuctionType={auctionType}
					editingInitialState={editingInitialState?.contest} />
					<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
				<RubricFields formikRef={rubricFormikRef} currentAuctionType={auctionType}
					editingInitialState={editingInitialState?.rubric} />
					<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
				<TermsFields formikRef={termsFormikRef} 
					editingInitialState={editingInitialState?.terms} />
					<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
				<DatesFields formikRef={datesFormikRef} currentAuctionType={auctionType} />
					<Divider sx={{ mt: 3, mb: 2, ml: "auto", width: '98%' }} />
				<FilesFields formikRef={filesFormikRef} 
					editingInitialState={editingInitialState?.files} />
			</Grid>

			<Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

			<Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
				<Grid item xs="auto">
					<Button fullWidth variant="contained" size="large"
									onClick={onClose} color="secondary"
					>
						{t("auction.cancel")}
					</Button>
				</Grid>
				<Grid item xs="auto">
					<Button fullWidth variant="contained" color="primary"
									onClick={submit} size="large">
						{auctionEditing 
							? t("auction.save_changes") 
							: t("auction.create_contest")
						}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}
