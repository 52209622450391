import React, {useRef} from "react";
import {
    Autocomplete, Button, Divider, Grid, TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Field, FieldArray, FieldProps, Formik, FormikProps} from "formik";
import {initialRubricItem, initialValues, ValidationSchema} from "./formik";
import {DictionaryObject} from "../../../../../types/dictionary";
import {NewAuctionRubricForm, RubricItem} from "../../../../../types/newAuction";
import {ProductType} from "../../../../../utils/enums";
import {useDictionary} from "../../../../../utils/useDictionary";
import {ProductModal, RubricItem as RubricItemComponent} from "./components";

export type ProductTypeBundle = {
    dictionary: DictionaryObject[];
    selected: DictionaryObject;
    onSelectChange: (
        e: React.SyntheticEvent<Element, Event>,
        newValue: DictionaryObject | null
    ) => void;
}

export type RubricItemsBundle = {
    resetItems: () => void;
    editItem: (index: number, data: RubricItem) => void;
    deleteItem: (index: number) => void;
    productType: ProductType;
}

export const RubricFields = ({
                                 formikRef, currentAuctionType, editingInitialState
                             }: {
    formikRef: React.RefObject<FormikProps<NewAuctionRubricForm>>;
    currentAuctionType: string;
    editingInitialState?: NewAuctionRubricForm
}) => {
    const onSubmit = (values: NewAuctionRubricForm) => {
    };
    const {t} = useTranslation();

    const productType = useDictionary(
        ProductType,
        (type) => ({value: type, label: t(`product_type.${type}`)}),
        editingInitialState
            ? editingInitialState.productType
            : {value: ProductType.product, label: t("product_type.product")}
    );

    const [isProductModalOpen, setIsProductModalOpen] = React.useState(false);
    const [currentModalIdx, setCurrentModalIdx] = React.useState(0);
    const onProductModalOpen = (itemIndex: number) => {
        setCurrentModalIdx(itemIndex);
        setIsProductModalOpen(true);
    };
    const onProductModalClose = () => setIsProductModalOpen(false);

    const productBundle = {
        isOpen: isProductModalOpen,
        onClose: onProductModalClose,
        currentModalIdx
    };

    const renderCount = useRef(0);
    React.useEffect(() => {
        formikRef.current?.setFieldValue("productType", productType.selected, false);

        if (!editingInitialState || renderCount.current >= 1) {
            formikRef.current?.setFieldValue("items", [initialRubricItem]);
        }

        renderCount.current += 1;
    }, [productType.selected]);

    return (
        <Formik innerRef={formikRef}
                initialValues={editingInitialState || initialValues}
                validationSchema={ValidationSchema()}
                onSubmit={onSubmit}
                validateOnMount
        >
            {(frops: FormikProps<NewAuctionRubricForm>) => (
                <React.Fragment>
                    <Grid container columnSpacing={3} rowSpacing={1}>
                        <Grid item xs={12} sx={{mb: 1}}>
                            <Typography variant="h6">
                                {t("auction.sections.rubric")}
                            </Typography>
                        </Grid>

                        <Grid item container columnSpacing={3} rowSpacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    disablePortal size="small"
                                    options={productType.dictionary}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={productType.selected}
                                    onChange={productType.onSelectChange}
                                    renderInput={params =>
                                        <Field name={"productType"}>
                                            {({field, form, meta}: FieldProps) => (
                                                <TextField {...params} fullWidth color="primary"
                                                           label={t("auction.dictionary.productType")}
                                                           error={(meta.touched && meta.error) ? true : false}
                                                           helperText={meta.touched && meta.error}
                                                />
                                            )}
                                        </Field>
                                    }
                                />
                            </Grid>
                            <Grid item sx={{alignSelf: "center"}}>
                                <Typography fontSize={12}>
                                    {t("auction.rubric.reset_warning")}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider sx={{mt: 3, mb: 1, ml: "auto", mr: "auto", width: '85%'}}/>

                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                                <Grid item>
                                    <ProductModal
                                        productBundle={productBundle}
                                        productType={frops.values.productType}
                                        items={frops.values.items}
                                        arrayHelpers={arrayHelpers}
                                    />

                                    {frops.values.items.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            <RubricItemComponent idx={idx}
                                                                 frops={frops} arrayHelpers={arrayHelpers}
                                                                 onProductModalOpen={onProductModalOpen}
                                            />

                                            {currentAuctionType !== "auction" &&
                                                <Divider sx={{mt: 3, mb: 2, ml: "auto", mr: "auto", width: '90%'}}/>
                                            }
                                        </React.Fragment>
                                    ))}

                                    {currentAuctionType !== "auction" &&
                                        <Grid item container columnSpacing={3} rowSpacing={1}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Button color="primary" variant="contained" size="small"
                                                        onClick={() => arrayHelpers.push(initialRubricItem)}
                                                >
                                                    {t("auction.modals.product.add_item")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            )}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Formik>
    )
}
