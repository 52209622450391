import { StorageType } from "../../utils/enums";

export const NCA_LAYER_URI = "wss://127.0.0.1:13579";



const signXML = (xml: string, storageType: StorageType): Promise<string> => {
    return new Promise(function(resolve, reject) {
        const ncaLayer = new WebSocket(NCA_LAYER_URI)
        ncaLayer.onopen = () => {

            const xmlToSign = {
                module: "kz.gov.pki.knca.commonUtils",
                method: "signXml",
                args: [storageType, "SIGNATURE", xml, '', '']
            };

            //setInterval(() => ncaLayer.send(NCA_LAYER_HEARTBEAT), 10000);
            ncaLayer.send(JSON.stringify(xmlToSign))
        }
        ncaLayer.onmessage = response => {
            if (response.data.includes("storage.empty")) reject("storage.empty");
            if (response.data.includes("action.canceled")) reject("action.canceled");

            // закрываем соединение после успешного подписания, либо если нажали Отмена
            const signedData = JSON.parse(response.data)
            if (signedData.result === undefined) {
                ncaLayer.close()
                resolve(signedData.responseObject);
            }
        }
        ncaLayer.onerror = error => {
            console.error('onerror', error)
            reject("NO_NCALAYER")
        }
    });
}

// интерфейс модуля
export interface SignatureUse {
    signXML: (xml: string, storageType: StorageType) => Promise<string>
}

// использование модуля
export default function useSignature(): SignatureUse {
    return {
        signXML
    }
}
