import React from "react";
export type DictionaryObject = { value: string, label: string };

export function useDictionary<T>(
  dictionaryEnum: T, 
  dictionaryMapping: (type: string) => DictionaryObject,
  initialSelected: DictionaryObject | null
) {
  const dictionary = Object.values(dictionaryEnum).map(dictionaryMapping);
  const [selected, setSelected] = React.useState(initialSelected);

  const onSelectChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: DictionaryObject | null,
  ) => { setSelected(newValue ? newValue : selected) };

  return { dictionary, selected, onSelectChange, setSelected }
}

export function useMultiDictionary<T>(
    dictionaryEnum: T,
    dictionaryMapping: (type: string) => DictionaryObject,
    initialSelected: DictionaryObject[] | null
) {
  const dictionary = Object.values(dictionaryEnum).map(dictionaryMapping);
  const [selected, setSelected] = React.useState(initialSelected);

  const onSelectChange = (
      e: React.SyntheticEvent<Element, Event>,
      newValue: DictionaryObject[] | null,
  ) => { setSelected(newValue ? newValue : selected) };

  return { dictionary, selected, onSelectChange, setSelected }
}