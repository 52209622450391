import React, { ReactChild } from 'react';
import PropTypes from 'prop-types';
import Box, { BoxProps } from '@mui/material/Box';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

const Container = ({ children, ...rest }: BoxProps) => (
  <Box
    maxWidth={{ sm: 720, md: 1236 }}
    width={'100%'}
    margin={'0 auto'}
    paddingX={2}
    paddingY={{ xs: 4, sm: 6, md: 8 }}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
