import {t} from "i18next";
import * as Yup from "yup";
import { NewCompanyCEOForm } from "../../../../../types/newCompany";
import { maskedPhoneNumber } from "../../../../../utils/regexs";

export const initialValues: NewCompanyCEOForm = {
  ceoName: "",
  ceoPhone: ""
};

export function ValidationSchema() {
  return Yup.object({
    ceoName: Yup.string().required(t("validation.required")),
    ceoPhone: Yup.string()
      .required(t("validation.required"))
      .matches(maskedPhoneNumber, t("validation.incorrect"))
  });
}
