import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '../../../common/Container';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {State} from "../../../redux/store";
import {Company} from "../../../types/company";
import {Authority, CompanyState} from "../../../utils/enums";
import {useNavigate} from "react-router-dom";

const Introduction = ({ themeMode = 'light' }: { themeMode: "light" | "dark" }) => {
  const { t } = useTranslation();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;
  const [isActive, setIsActive] = useState(company?.state === CompanyState.active)
  const user = useSelector((state: State) => state.auth.user);
  const isUser = user && user.authorities[user.authorities.length - 1] === Authority.USER;
  const navigate = useNavigate()
  useEffect(() => {
    if (isUser && company?.state !== CompanyState.active) {
      setIsActive(false)
    } else if(isUser && company?.state === CompanyState.active) {
      setIsActive(true)
    }
  }, [company]);
  return (
    <Box>
      <Container>
        <Box>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            align={'center'}
            color={'textSecondary'}
          >
            {t("cabinet.account_intro.suptitle")}
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            sx={{
              fontWeight: 700,
            }}
          >
            {t("cabinet.account_intro.title")}
          </Typography>

          { isActive ?
            <Box marginTop={4}>
              <Typography gutterBottom>{t("cabinet.account_intro.text1")}</Typography>
              <Typography gutterBottom>{t("cabinet.account_intro.text2")}</Typography>
              <Typography gutterBottom>{t("cabinet.account_intro.text3")}</Typography>
              <Typography gutterBottom>{t("cabinet.account_intro.text4")}</Typography>
              <Typography gutterBottom>{t("cabinet.account_intro.text5")}</Typography>
            </Box> :

            <Box marginTop={4}>
              <Typography gutterBottom>{t("cabinet.account_intro.text6")}</Typography>
            </Box>
          }
          <Box marginTop={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {t("cabinet.account_intro.feedback")}
            </Typography>
            <Typography gutterBottom>{t("cabinet.account_intro.schedule")}</Typography>
            <Typography gutterBottom>{t("cabinet.account_intro.support_email")}</Typography>
            <Typography gutterBottom>{t("cabinet.account_intro.call_center")}</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Introduction.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Introduction;
