import { FormikProps, FormikTouched, setNestedObjectValues } from "formik";

export const required = "Обязательное поле";
export const requiredDateAndTime = "Укажите дату и время";

export const formValidation = async (
  formikRef: React.RefObject<FormikProps<any>>, 
) => {
  if (formikRef.current && formikRef.current.validateForm) {
    return formikRef.current.validateForm()
      .then(errors => {
        if (Object.keys(errors).length === 0 && formikRef.current) {
          // console.log(formikRef.current.values);
          return true;
        }
        else {
          console.log(formikRef.current?.values);
          console.log(errors);
          formikRef.current?.setTouched(
            setNestedObjectValues<FormikTouched<any>>(errors, true)
          );
          return false;
        }
      })
      .catch(err => false)
  }
  else { return false }
};

