import {t} from "i18next";
import * as Yup from "yup";
import { NewAuctionContestForm } from "../../../../../types/newAuction";

export const initialValues: NewAuctionContestForm = {
  title: "",
  auctionCategory: { value: "", label: "" },
  auctionType: { value: "", label: "" },
  description: "",
  bidStep: { value: "", label: "" },
  bidDirection: { value: "", label: "" }
};

export function ValidationSchema() {
  return Yup.object({
    title: Yup.string().required(t("validation.required")),
    auctionCategory: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }).nullable(),
    auctionType: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }),
    description: Yup.string().required(t("validation.required")),
    bidStep: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }).nullable(),
    bidDirection: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }).nullable()
  })
}