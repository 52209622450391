import {useState, useEffect} from "react";
import {Autocomplete, Box, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useNavigate, useSearchParams} from "react-router-dom";
import Container from "../../../common/Container";
import {useSnack} from "../../../utils/useSnack";
import {Auction} from "../../../types/auction";
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from "react-i18next";
import {moderatorService} from "../../../api/services/moderator";
import {AuctionState, AuctionType} from "../../../utils/enums";
import {useDictionary, useMultiDictionary} from "../../../utils/useDictionary";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import {Column, columnStyle, largeColumnStyle, CompanyTitleColumn, GrossColumn} from "../../../common/Columns";
import {DictionaryObject} from "../../../types/dictionary";

export default function Auctions() {
    const {t} = useTranslation();
    const {snack} = useSnack();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(searchParams.get("text") || "");
    const [page, setPage] = useState(+(searchParams.get("page") || "0"));
    const [pageSize, setPageSize] = useState(+(searchParams.get("pageSize") || "8"));
    const [totalElements, setTotalElements] = useState(0);

    const search = (text: string) => getAuctions(text);
    const onClearSearch = () => {
        setSearchText("");
        search("")
    };
    const onPageChange = (newPage: number) => {
        setPage(newPage);
    }
    const onPageSizeChange = (newSize: number) => {
        setPage(0);
        setPageSize(newSize)
    };

    const hasStates = !!searchParams.get('states')
    const hasType = !!searchParams.get('type')

    const [sort, setSort] = useState(hasStates);
    const [sortType, setSortType] = useState(hasType);

    const auctionState = useMultiDictionary(
        AuctionState,
        (type) => ({value: type, label: t(`auction_state.${type}`)}),
        hasStates ? searchParams?.getAll('states')?.map((state: string) => {
            return {
                value: state || AuctionState.draft,
                label: t(`auction_state.${state || AuctionState.draft}`)
            }
        }) : [{value: AuctionState.draft, label: t("auction_state.draft")}]
    );

    const auctionType = useDictionary(
        AuctionType,
        (type) => ({value: type, label: t(`auction_type.${type}`)}),
        hasStates ? {value: searchParams.get('type') || AuctionType.tender,
            label: t(`auction_type.${searchParams.get('type') || AuctionType.tender}`)} : {value: AuctionType.tender, label: t("auction_type.tender")}
    );

    async function getAuctions(text: string) {
        try {
            setLoading(true);
            if(!!text) {
                searchParams.set("text", text)
            } else {
                searchParams.delete("text")
            }
            searchParams.set("page", `${page}`)
            searchParams.set("pageSize", `${pageSize}`);
            if(auctionState.selected?.length !== 0 && sort) {
                auctionState!.selected!.forEach((obj: DictionaryObject, index: number) => {
                    if(index === 0) {
                        searchParams.set("states", obj.value)
                    } else {
                        searchParams.append("states", obj.value)
                    }
                })
            } else {
                searchParams.delete("states")
            }
            if(!!auctionType.selected?.value && sortType) {
                searchParams.set("type", auctionType.selected?.value);
            } else {
                searchParams.delete("type")
            }
            setSearchParams(searchParams)
            const response = await moderatorService
                .getAuctions(text, page, pageSize, sort ? auctionState.selected || [] : [], sortType ? auctionType.selected?.value : "");

            if (response.status === "success" && response.data) {
                setAuctions(response.data.data);
                setTotalElements(response.data.totalElements);
            } else {
                snack(t("snack.error.auctions"), "error");
            }
        } catch (err) {
            snack(t("snack.error.auctions"), "error");
        } finally {
            setLoading(false)
        }
    }

    function goToAuctionDetails(id: string) {
        navigate(`/moderator-auctions/${id}`, { state: { text: searchText, states: sort ? auctionState?.selected?.map(obj => obj.value)  : null,
                page: page, pageSize: pageSize, sort: sort, type: sort ? auctionType?.selected?.value : null, sortType: sortType }})
    }

    useEffect(() => {
        getAuctions(searchText)
    }, [searchParams, page, pageSize, auctionState.selected, sort, auctionType.selected, sortType]);

    return (
        <Box>
            <Container paddingY={{xs: 2, sm: 4}} maxWidth="unset">
                <Box marginBottom={4}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {t("auctions_page.contests")}
                    </Typography>
                </Box>

                <Grid item container spacing={2} sx={{mb: 1}}>
                    <Grid item xs={12} sm={11.5}>
                        <Autocomplete
                            multiple
                            disablePortal size="small" disabled={!sort} sx={{mb: .5}}
                            options={auctionState.dictionary}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={auctionState.selected || []}
                            onChange={auctionState.onSelectChange}
                            renderInput={params =>
                                <TextField {...params} fullWidth color="primary"
                                           name="auctionState" label={t("auction.dictionary.state")}
                                />
                            }
                        />
                        {sort
                            ? <Button color="primary" onClick={() => setSort(false)}>
                                {t("auctions_page.disable_state_sort")}
                            </Button>
                            : <Button color="primary" onClick={() => setSort(true)}>
                                {t("auctions_page.enable_state_sort")}
                            </Button>
                        }
                    </Grid>

                    <Grid item xs={12} sm={3.5}>
                        <Autocomplete
                            disablePortal size="small" disabled={!sortType} sx={{mb: .5}}
                            options={auctionType.dictionary}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={auctionType.selected}
                            onChange={auctionType.onSelectChange}
                            renderInput={params =>
                                <TextField {...params} fullWidth color="primary"
                                           name="auctionType" label={t("auction.dictionary.type")}
                                />
                            }
                        />
                        {sortType
                            ? <Button color="primary" onClick={() => setSortType(false)}>
                                {t("auctions_page.disable_type_sort")}
                            </Button>
                            : <Button color="primary" onClick={() => setSortType(true)}>
                                {t("auctions_page.enable_type_sort")}
                            </Button>
                        }
                    </Grid>

                    <Grid item container xs={12} sm={8} spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField fullWidth color="primary" variant="outlined" size="small"
                                       label={t("auctions_page.search_auction")}
                                       value={searchText} onChange={e => setSearchText(e.target.value)}
                                       onKeyPress={e => {
                                           if (e.key === "Enter") {
                                               search(searchText)
                                           }
                                       }}
                                       InputProps={{
                                           endAdornment: searchText ? (
                                               <IconButton size="small" onClick={onClearSearch}>
                                                   <ClearIcon/>
                                               </IconButton>
                                           ) : undefined
                                       }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Button fullWidth color="primary" variant="contained"
                                    onClick={() => search(searchText)}
                            >
                                {t("auctions_page.search")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Box>
                    <DataGrid
                        components={{NoRowsOverlay: () => <NoRowsOverlay/>}}
                        sx={{
                            height: 500,
                            "& .MuiDataGrid-cell": {
                                whiteSpace: "pre-wrap"
                            },
                        }}
                        columnBuffer={8}
                        rows={auctions}
                        columns={[
                            Column("state", 120, columnStyle, "auction_state"),
                            Column("participantCount", 100, columnStyle),
                            Column("bidCount", 125, columnStyle),
                            Column("auctionNo", 140, {...columnStyle, ...largeColumnStyle}),
                            Column("title", 165, {...columnStyle, ...largeColumnStyle}),
                            Column("description", 165, {...columnStyle, ...largeColumnStyle}),
                            CompanyTitleColumn(165, columnStyle),
                            GrossColumn(200, columnStyle),
                            Column("auctionType", 125, columnStyle, "auction_type"),
                        ]}
                        onRowClick={(rowData) => goToAuctionDetails(rowData.row.id)}
                        page={page} onPageChange={onPageChange}
                        pageSize={pageSize} onPageSizeChange={onPageSizeChange}
                        rowCount={totalElements} rowsPerPageOptions={[4, 8, 16]}
                        loading={loading} paginationMode="server"
                        density="comfortable" style={{whiteSpace: "pre-wrap"}}
                    />
                </Box>
            </Container>
        </Box>
    )
}