import { AnyAction } from "redux"

export const SET_CURRENT_TIME = "SET_CURRENT_TIME";

const initialState = {
  currentTime: null
}

export const publicReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CURRENT_TIME: return { ...state,
      currentTime: action.payload
    }

    default: return state
  }
}
