import React, { useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import Container from '../../../common/Container';
import { Company, CompanyFile } from '../../../types/company';
import { State } from '../../../redux/store';
import { companyService } from '../../../api/services/company';
import { NewFileModal } from '../../../common/FilesModals/New';
import { FileCategory, FileState } from '../../../utils/enums';
import { useDictionary } from '../../../utils/useDictionary';
import { useSnack } from '../../../utils/useSnack';
import { publicService } from '../../../api/services/public';
import NoRowsOverlay from '../../../common/NoRowsOverlay';
import { DeleteFileModal } from './components/DeleteFileModal';
import { columnStyle } from '../../../common/Columns';

export default function Files() {
	const { t } = useTranslation();
	const { snack } = useSnack();
	const location = useLocation();
	const state = useSelector((state: State) => state);
	const company: Company | null = state.company.company;

	const [files, setFiles] = useState<CompanyFile[]>([]);
	const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(false);
  const fileCategory = useDictionary(
    FileCategory, 
    (type) => ({value: type, label: t(`file_category.${type}`)}),
    { value: FileCategory.other, label: t("file_category.other") }
  );

  const getFiles = async () => {
    if (!company) return;

    try {
      setLoading(true);
      const response = await companyService.getCompanyFiles(
        company.id,
        sort ? fileCategory.selected?.value : undefined
      );
      if (response.status === "success" && response.data) {
        setFiles(response.data ? response.data : []);
      }
      else { snack(t("snack.error.docs"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.docs"), "error"); 
    }
    finally { setLoading(false) }
  };
  
  const [downloadingId, setDownloadingId] = React.useState("");
  const downloadFile = async (file: CompanyFile) => {
    if (!company) return;
    
    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await companyService.getFileToken(company.id, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.name);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.file_download"), "error"); 
    }
    finally { setDownloadingId("") }
  };

  const [isNewOpen, setIsNewOpen] = React.useState(false);
  const onOpenNew = () => setIsNewOpen(true);
  const onCloseNew = () => setIsNewOpen(false);

  const [deletingId, setDeletingId] = React.useState("");
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const onOpenDelete = (file: CompanyFile) => {
    setDeletingId(file.id)
    setIsDeleteOpen(true);
  };
  const onCloseDelete = () => setIsDeleteOpen(false);

	React.useEffect(() => {
    if (company?.id) getFiles() 
  }, [company, sort, fileCategory.selected?.value, location.pathname]);

	return (
    <Box>
      <NewFileModal isOpen={isNewOpen} onClose={onCloseNew} 
        getFiles={getFiles}
      />
      <DeleteFileModal isOpen={isDeleteOpen} onClose={onCloseDelete} 
        getFiles={getFiles} fileId={deletingId}
      />

      <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {t("pages.account.files")}
          </Typography>
        </Box>

        <Grid item container spacing={2} alignItems={"flex-start"} mb={2}>
          <Grid item xs={12} sm={3.5}>
            <Autocomplete
              disablePortal size="small" disabled={!sort} sx={{ mb: .5 }}
              options={fileCategory.dictionary}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={fileCategory.selected} 
              onChange={fileCategory.onSelectChange}
              renderInput={params => 
                <TextField {...params} fullWidth color="primary" 
                          name="fileCategory" 
                          label={t("auction.dictionary.fileCategory")}
                /> 
              } 
            />
            {sort
              ? <Button color="primary" onClick={() => setSort(false)}>
                  {t("docs.disable_category_sort")}
                </Button>
              : <Button color="primary" onClick={() => setSort(true)}>
                  {t("docs.enable_category_sort")}
                </Button>
            }
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Button fullWidth color="primary" variant="contained"
              onClick={onOpenNew}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {t("docs.add_new_file")}
              </span>
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            '& .super-app-theme--archived': {
              bgcolor: (theme) => "rgba(0,0,0,.1)",
              pointerEvents: "none"
            }
          }}
        >
          <DataGrid 
            components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
            sx={{
              height: 500,
              "& .MuiDataGrid-cell": {
                whiteSpace: "pre-wrap"
              },
            }}
            getRowClassName={(params) => `super-app-theme--${params.row?.state}`}
            disableColumnMenu
            columnBuffer={7}
            rows={files}
            columns={[
              { 
                field: 'category', headerName: t("auction.table_columns.category"), width: 150,
                renderCell: params => (
                  <Tooltip title={t(`file_category.${params.row?.category}`) as string}>
                    <div style={columnStyle}>
                      <div>{t(`file_category.${params.row?.category}`)}</div>
                    </div>
                  </Tooltip>
                )
							},
              { field: 'title', headerName: t("auction.table_columns.title"), width: 250,
                renderCell: params => (
                  <Tooltip title={params.row?.title}>
                    <div style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div>{params.row?.title}</div>
                    </div>
                  </Tooltip>
                ),
              },
              { field: 'description', headerName: t("auction.table_columns.description"), width: 300,
                renderCell: params => (
                  <Tooltip title={params.row?.description ?? ""}>
                    <div style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div>{params.row?.description}</div>
                    </div>
                  </Tooltip>
                ),
              },
              { field: 'name', headerName: t("auction.table_columns.name1"), width: 300,
                renderCell: params => (
                  <Tooltip title={params.row?.name}>
                    <div style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div>{params.row?.name}</div>
                    </div>
                  </Tooltip>
                ),
              },
              {
                field: 'download', headerName: '', sortable: false, 
                renderCell: params => (
                  <div style={columnStyle}>
                    <Button variant="contained" size="small" color="secondary"
                            onClick={() => downloadFile(params.row)}
                            disabled={downloadingId === params.row?.id || params.row?.state === "archived"}
                    >
                      {t("docs.download")}
                    </Button>
                  </div>
                )
              },
              {
                field: 'delete', headerName: '', sortable: false, 
                renderCell: params => (
                  <div style={columnStyle}>
                    <Button variant="contained" size="small" color="error"
                            onClick={() => onOpenDelete(params.row)}
                            disabled={downloadingId === params.row?.id || params.row?.state === "archived"}
                    >
                      {t("docs.delete")}
                    </Button>
                  </div>
                )
              },
              {
                field: 'state', headerName: t("auction.table_columns.state"), sortable: false, 
                renderCell: params => (
                  <Tooltip title={t(`file_state.${params.row?.state}`) as string}>
                    <div style={columnStyle}>
                      <div>{t(`file_state.${params.row?.state}`)}</div>
                    </div>
                  </Tooltip>
                )
              }
            ]}
            loading={loading} paginationMode="server"
            density="comfortable" style={{ whiteSpace: "pre-wrap" }}
          />
        </Box>
      </Container>
    </Box>
	);
};
