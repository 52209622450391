import React, { useEffect, useState } from "react";
import { Button, Chip, Divider, Grid, LinearProgress, Paper, TextField,
  Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSnack } from "../../../utils/useSnack";
import { publicService } from "../../../api/services/public";
import { Auction, AuctionFile } from "../../../types/auction";
import digitsOnly from "../../../utils/digitsOnly";
import Container from "../../../common/Container";
import { AuctionType } from "../../../utils/enums";
import { switchTitleText } from "../../../utils/switchAuctionText";
import { ThousandsSeparator } from "../../../common/NumberFormat";

export default function AuctionDetails() {
  const { snack } = useSnack();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClose = () => navigate("/page-auctions");
  const location = useLocation();
  const pathArray = location.pathname.split('/');

  const auctionNo = pathArray[pathArray.length - 1];

  const [loading, setLoading] = useState(false);
  const [auction, setAuction] = useState<Auction | null>(null);

  async function getAuction() {
    setLoading(true);
    try {
      const response = await publicService.getAuction(auctionNo);
      if (response.status === "success" && response.data) { setAuction(response.data) }
      else { snack(detailsErrorMessage(), "error") }
    }
    catch (error) { const err = error as AxiosError }
    finally { setLoading(false) }
  }

  const detailsErrorMessage = (): string => {
    switch (auction?.auctionType) {
      case AuctionType.tender:
        return t("snack.error.tender_details")
      case AuctionType.price_offer:
        return t("snack.error.price-offer_details")
      case AuctionType.single_source:
        return t("snack.error.single-source_details")
      default:
        return t("snack.error.auction_details")
    }
  }

  const [downloadingId, setDownloadingId] = React.useState("");
  async function downloadFile(file: AuctionFile) {
    if (!auction) return;

    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await publicService.getFileToken(auction.id, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.title);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.file_download"), "error");
    }
    finally { setDownloadingId("") }
  };

  useEffect(() => {
    if (auctionNo) getAuction(); return () => setAuction(null);
  }, [auctionNo]);

  const auctionOrTender = auction?.auctionType === AuctionType.auction || auction?.auctionType === AuctionType.tender;

  const loadingMessage = (): string => {
    switch (auction?.auctionType) {
      case AuctionType.tender:
        return t("tender.loading")
      case AuctionType.price_offer:
        return t("price-offer.loading")
      case AuctionType.single_source:
        return t("single-source.loading")
      default:
        return t("auction.loading")
    }
  }

  return (
    <Container paddingY={{ xs: 2, sm: 4 }}>
      <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <Typography component="h5" variant="h5" sx={{ fontWeight: 700 }}>
              {switchTitleText(auction)} {auction ? `№${auction.auctionNo}` : ""}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2, mb: 6, width: "100%" }} />

        {loading
          ? <Box>
              <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
              <Typography>{loadingMessage()}</Typography>
            </Box>
          : !auction
              ? <Box>
                  <Typography>{detailsErrorMessage()}</Typography>
                </Box>
              : <Grid container rowSpacing={3}>
                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.dictionary.state")}
                        value={t(`auction_state.${auction.state}`)}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.participantCount")}
                        value={auction.participantCount}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.bidCount")}
                        value={auction.bidCount}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography variant="h6">
                        {t("auction.sections.customer")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.companyTitle")}
                        value={auction.company.title}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.companyItn")}
                        value={digitsOnly(auction.company.itn)}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.supervisor")}
                        value={auction.supervisor.name}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography variant="h6">
                        {t("auction.sections.contest")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.auctionTitle")}
                        value={auction.title}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.dictionary.auctionType")}
                        value={t(`auction_type.${auction.auctionType}`)}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} sx={{ mt: 1 }}>
                      <TextField multiline minRows={4}
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.auctionDescription")}
                        value={auction.description}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  {auction.auctionType === AuctionType.auction
                    ? <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.dictionary.auctionCategory")}
                            value={t(`auction_category.${auction.auctionCategory}`)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.dictionary.bidStep")}
                            value={t(`bid_step.${auction.bidStep}`)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.dictionary.bidDirection")}
                            value={t(`bid_direction.${auction.bidDirection}`)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>
                    : null
                  }

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography variant="h6">
                        {t("auction.sections.rubric")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.dictionary.productType")}
                        value={t(`product_type.${auction.productType}`)}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ mt: 3, mb: 1, ml: "auto", mr: "auto", width: '85%' }} />

                  {auction.items.map((item, idx, items) => (
                    <Box key={idx}>
                      <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <b>
                            {t("auction.fields.rubric.item")}
                            {items.length > 1 ? ` #${idx + 1}` : ""}
                          </b>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.productCode")}
                            value={auction.items[idx].productCode}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.productName")}
                            value={auction.items[idx].productName}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} sx={{ mt: 1 }}>
                          <TextField multiline minRows={4}
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.productBrief")}
                            value={auction.items[idx].productBrief}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.price")}
                            value={auction.items[idx].price}
                            InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.dictionary.unit")}
                            value={t(`unit.${auction.items[idx].unit}`)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.amount")}
                            value={auction.items[idx].amount}
                            InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.dictionary.deliveryRegion")}
                            value={t(`delivery_region.${auction.items[idx].deliveryRegion}`)}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                          <TextField
                            fullWidth color="primary" type="text" size="small"
                            label={t("auction.fields.rubric.deliveryLocation")}
                            value={auction.items[idx].deliveryLocation}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}

                  <Divider sx={{ mt: 3, mb: 1, ml: "auto", mr: "auto", width: '85%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.rubric.gross")}
                        value={auction.gross}
                        InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography variant="h6">
                        {t("auction.sections.terms")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.dictionary.deliveryCondition")}
                        value={t(`delivery_condition.${auction.deliveryCondition}`)}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.deliveryTime")}
                        value={auction.deliveryTime}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography variant="h6">
                        {t("auction.sections.dates")}
                      </Typography>
                    </Grid>
                    {auctionOrTender
                      ? <>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth color="primary" type="text" size="small"
                              label={t("auction.fields.registrationOpenTime")}
                              value={auction.registrationOpenTime.replace("T", " ")}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth color="primary" type="text" size="small"
                              label={t("auction.fields.registrationCloseTime")}
                              value={auction.registrationCloseTime.replace("T", " ")}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>
                        </>
                      : null
                    }
                  </Grid>

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.bidOpenTime")}
                        value={auction.bidOpenTime.replace("T", " ")}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth color="primary" type="text" size="small"
                        label={t("auction.fields.bidCloseTime")}
                        value={auction.bidCloseTime.replace("T", " ")}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>

                  {auctionOrTender
                    ? <>
                        <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />
                        <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                          <Grid item xs={12} sx={{ mb: 1 }}>
                            <Typography fontSize={12}>
                              {t("auction.fields.aboutApprovementTime")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth color="primary" type="text" size="small"
                              label={t("auction.fields.approvementOpenTime")}
                              value={auction.registrationCloseTime.replace("T", " ")}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth color="primary" type="text" size="small"
                              label={t("auction.fields.approvementCloseTime")}
                              value={auction.bidOpenTime.replace("T", " ")}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    : null
                  }

                  <Divider sx={{ mt: 3, ml: "auto", width: '98%' }} />

                  <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {t("auction.sections.docs")}
                      </Typography>
                    </Grid>

                    <Grid item container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
                      {auction.attachments.map(file => (
                        <Grid item key={file.id}>
                          <Typography fontSize={12} textAlign={"center"} mt={1} mb={.5}>
                            {t(`file_category.${file.category}`)}
                          </Typography>

                          <Chip label={file.title}
                            onClick={() => downloadFile(file)}
                            disabled={downloadingId === file.id}
                          />

                          {downloadingId === file.id &&
                            <LinearProgress color="primary" />
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
        }

        <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

        <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
          <Grid item xs="auto">
            <Button fullWidth variant="contained" size="large"
                    onClick={onClose} color="secondary"
            >
              {t("auction.close")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
