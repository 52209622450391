export const pages = [
  {
    title: 'pages.moderator.contests',
    id: 'auctions',
    pages: [
      {
        title: 'pages.moderator.contests',
        href: '/moderator-auctions',
      },
    ],
  },
  {
    title: 'pages.moderator.companies',
    id: 'companies',
    pages: [
      {
        title: 'pages.moderator.companies-registry',
        href: '/moderator-companies',
      },
    ],
  },
];
