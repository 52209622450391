import { AxiosResponse } from "axios";
import { Response, PaginationResponse } from "../../types/api";
import { Auction } from "../../types/auction";
import { BidsResponseData, PlaceBidData } from "../../types/bids";
import { DictionaryObject } from "../../types/dictionary";
import { CompanyExists, NewAuctionForm, ProductsObject } from "../../types/newAuction";
import { ParticipantStatusData, ParticipateData } from "../../types/participant";
import protectedInstance from "../instances/protected";

async function getAuctions(
  companyId: string, text: string, page: number, pageSize: number, 
	auctionsEndpoint: string, auctionType?: string
) {
  const query = `query=${text}&page=${page}&page_size=${pageSize}` +
								`&type=${auctionType ? auctionType : ""}`;

  return protectedInstance
    .get(`/company/${companyId}/auctions/${auctionsEndpoint}?${query}`)
    .then((res: AxiosResponse<PaginationResponse<Auction[]>>) => res.data)
}

async function getAuction(companyId: string, auctionId: string) {
  return protectedInstance
    .get(`/company/${companyId}/auction/${auctionId}`)
    .then((res: AxiosResponse<Response<Auction>>) => res.data)
}

async function signAuction(companyId: string, auctionId: string, signature: string) {
  return protectedInstance
    .post(
      `/company/${companyId}/auction/${auctionId}/passport/execute`, { signature }
    )
    .then((res: AxiosResponse<Response<{ errorMessage: string | null }>>) => res.data)
}

async function createAuction(companyId: string, data: NewAuctionForm) {
	return protectedInstance
		.post(`/company/${companyId}/auction`, data)
		.then((res: AxiosResponse<Response<Auction>>) => res.data)
}

async function editAuction(companyId: string, auctionId: string, data: NewAuctionForm) {
	return protectedInstance
		.post(`/company/${companyId}/auction/${auctionId}`, data)
		.then((res: AxiosResponse<Response<Auction>>) => res.data)
}

async function searchProducts(
	productType: DictionaryObject, searchText: string, page: number, pageSize: number
) {
	const query = `type=${productType.value}&query=${searchText}` +
								`&page=${page}&page_size=${pageSize}`;

	return protectedInstance
		.get(`/data/items/russian?${query}`)
		.then((res: AxiosResponse<PaginationResponse<ProductsObject[]>>) => res.data)
};

async function checkCompanyExists(itn: string) {
  return protectedInstance
    .get(`/check/company/bin/${itn}`)
    .then((res: AxiosResponse<Response<CompanyExists>>) => res.data)
}

async function getFileToken(auctionId: string, companyId: string, fileId: string) {
  return protectedInstance
    .get(`/auction/${auctionId}/company/${companyId}/file/${fileId}`)
    .then((res: AxiosResponse<any>) => res.data)
}

async function changeParticipantStatus(
  companyId: string, auctionId: string, data: ParticipantStatusData
) {
  return protectedInstance
    .post(`/company/${companyId}/auction/${auctionId}/participation/status`, data)
    .then((
      res: AxiosResponse<Response<{ errorMessage: string | null }> & 
      { errorMessage: string | null }>
    ) => res.data)
}

async function cancelAuction(companyId: string, auctionId: string) {
  return protectedInstance
    .post(`/company/${companyId}/auction/${auctionId}/cancel`)
    .then((res: AxiosResponse<Response<any>>) => res.data)
}

async function saveParticipationReview(companyId: string, auctionId: string, formData: FormData) {
  return protectedInstance
    .post(`/company/${companyId}/auction/${auctionId}/participation/review`, formData)
    .then((res: AxiosResponse<Response<any>>) => res.data)
}

async function getBids(companyId: string, auctionId: string) {
  return protectedInstance
    .get(`/company/${companyId}/auction/${auctionId}/bids`)
    .then((res: AxiosResponse<Response<BidsResponseData>>) => res.data)
}

async function placeSteppingBid(
  auctionId: string, 
  data: { companyId: string; biddingKey: number }
) {
  return protectedInstance
    .post(`/auction/${auctionId}/step_bid`, data)
    .then((res: AxiosResponse<Response<{ errorMessage: null }>>) => res.data)
}

async function placeBid(auctionId: string, data: PlaceBidData) {
  return protectedInstance
    .post(`/auction/${auctionId}/bid`, data)
    .then((res: AxiosResponse<Response<{ errorMessage: null }>>) => res.data)
}

async function participate(auctionId: string, data: ParticipateData) {
  return protectedInstance
    .post(`/auction/${auctionId}/participate`, data)
    .then((
      res: AxiosResponse<Response<{ errorMessage: string | null }> & 
      { errorMessage: string | null }>
    ) => res.data)
}

export const auctionService = {
  getAuctions,
  getAuction,
  signAuction,
  createAuction,
  editAuction,
  searchProducts,
  checkCompanyExists,
  getFileToken,
  changeParticipantStatus,
  cancelAuction,
  saveParticipationReview,
  getBids,
  placeSteppingBid,
  placeBid,
  participate
}