/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { authService } from '../../../../../api/services/auth';
import { AxiosError } from 'axios';
import { useSnack } from '../../../../../utils/useSnack';
import { useNavigate } from 'react-router-dom';
import { setLSToken } from '../../../../../utils/token';
import { Authority } from '../../../../../utils/enums';
import { useDispatch } from 'react-redux';
import { SET_USER } from '../../../../../redux/reducers/auth';
import { useTranslation } from 'react-i18next';

const Form = () => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t("validation.email_format"))
      .required(t("validation.email_required")),
    password: yup
      .string()
      .required(t("validation.password_required"))
  });

  const { snack } = useSnack();
  const navigate = useNavigate();
  const initialValues = { email: "", password: "" };
  const dispatch = useDispatch();

  async function onSubmit({ email, password }: {
    email: string; password: string
  }) {
    try {
      const response = await authService.login({ username: email, password });

      if (response.status === "success" && response.data) {
        snack(t("snack.success.login"), "success");

        const { tokenData, profileData } = response.data;
        setLSToken(tokenData);

        dispatch({ type: SET_USER, payload: profileData });

        switch(profileData.authorities[profileData.authorities.length - 1]) {
          case Authority.USER: return navigate("/account-introduction")
          case Authority.MODERATOR: return navigate("/moderator-introduction")
          case Authority.ADMIN: return navigate("/admin-introduction")

          default: return
        }
      }

      if (response.code === "bad_credentials") {
        snack(t("snack.error.email_or_password"), "error")
      }
      else { snack("snack.error.login", "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'textSecondary'}
        >
          {t("pages.login")}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {t("login_page.welcome_back")}
        </Typography>
        <Typography color="text.secondary">
          {t("login_page.auth_to_enter")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("login_page.enter_email")}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email || null}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  {t("login_page.enter_password")}
                </Typography>
              </Box>
              <Typography variant={'subtitle2'}>
                <Link
                  component={'a'}
                  color={'primary'}
                  href={'/page-forgot-password'}
                  underline={'none'}
                >
                  {t("login_page.forgot_password")}
                </Link>
              </Typography>
            </Box>
            <TextField
              label={`${t("inputs.password")} *`}
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password || null}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  {t("login_page.dont_have_account")}{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/page-signup'}
                    underline={'none'}
                  >
                    {t("login_page.signup_here")}
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                {t("login_page.go_login")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
