/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {AxiosError} from 'axios';
import {authService} from '../../../../../api/services/auth';
import {useSnack} from '../../../../../utils/useSnack';
import {SignupFinalStepData, SignupFirstStepData} from '../../../../../types/auth';
import {useNavigate} from 'react-router-dom';
import {maskedPhoneNumber} from '../../../../../utils/regexs';
import {PhoneMaskInputComponent} from '../../../../../common/NumberFormat';
import digitsOnly from '../../../../../utils/digitsOnly';
import {t} from "i18next";
import SignatureBtn from "../../../../../common/SignatureBtn";
import useSignature from "../../../../../api/services/signature";
import {useDictionary} from "../../../../../utils/useDictionary";
import {CompanyForm, StorageType} from "../../../../../utils/enums";
import {VerifySubject} from "../../../../../types/token";
import {Response} from "../../../../../types/api";

type FirstStepValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

type FinalStepValues = {
  code: string
}

const Form = () => {
  const validationSchemas = [
    yup.object({
      firstName: yup
        .string()
        .trim()
        .required(t("validation.first_name_required")),
      lastName: yup
        .string()
        .trim()
        .required(t("validation.last_name_required")),
      iinOrBin: yup
         .string()
         .trim()
         .required(t("validation.new_bin_required")),
      orgName: yup
          .string()
          .trim()
          .required(t("validation.new_org_name_required")),
      email: yup
        .string()
        .trim()
        .required(t("validation.email_required"))
        .email(t("validation.email_format")),
      phone: yup
        .string()
        .trim()
        .matches(maskedPhoneNumber, t("validation.phone_format"))
        .required(t("validation.phone_required")),
      password: yup
        .string()
        .trim()
        .required(t("validation.password_required")),
      сonfirmPassword: yup
        .string()
        .trim()
        .required(t("validation.password_confirm_required"))
        .oneOf([yup.ref('password')], t("validation.passwords_dont_match")),
    }),
    yup.object({
      code: yup
        .string()
        .min(6, t("validation.code_six_format"))
        .max(6, t("validation.code_six_format"))
        .required(t("validation.code_required"))
    }),
  ];

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { snack } = useSnack();
  const navigate = useNavigate();

  // if actionId is empty = form is in first step
  // if actionId is not empty = form is in final step
  const [actionId, setActionId] = useState("");
  const [companyForm, setCompanyForm] = useState(CompanyForm.kz_ip)

  const initialValues = [
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: "",
      password: '',
      confirmPassword: '',
      iinOrBin: '',
      orgName: ''
    },
    {
      code: "",
    }
];

  async function signupFirstStep(data: SignupFirstStepData) {
    const recaptchaToken = await executeRecaptcha?.("login");
    if (!recaptchaToken) { snack("Произошла ошибка", "error"); return }

    const response = await authService.signupFirstStep(data, recaptchaToken);

    if (response.status === "success" && response.data) {
      snack(t("snack.success.code_sent_to_email"), "success");
      return response.data.id;
    }
    else if (response.code === "user_already_exists") {
      snack(t("snack.error.user_already_exists"), "error")
    }
    else { snack(t("snack.error.default"), "error") }
  }

  async function signupFinalStep(data: SignupFinalStepData, actionId: string) {
    const response = await authService.signupFinalStep(data, actionId);

    if (response.status === "success") {
      snack(t("snack.success.signup"), "success");
      navigate("/page-login");
    }
    else if (response.code === "action_code_invalid") {
      snack(t("snack.error.action_code_invalid"), "error")
    }
    else if (response.code === "action_outdated") {
      snack(t("snack.error.action_outdated"), "error")
      setActionId("");
    }
    else { snack(t("snack.error.default"), "error") }
  }

  async function onSubmit(values: FirstStepValues | FinalStepValues) {
    let data: SignupFirstStepData | SignupFinalStepData;

    try {
      // first step
      if (!actionId) {
        const { firstName, lastName, email, phone, password } = values as FirstStepValues;
        data = {
          name: `${firstName} ${lastName}`,
          username: email,
          mobile: digitsOnly(phone),
          password
        };
        const id = await signupFirstStep(data as SignupFirstStepData);
        if (id) setActionId(id);
      }
      // final step
      else {
        const { code } = values as FinalStepValues;
        data = {
          code
        };
        signupFinalStep(data as SignupFinalStepData, actionId);
      }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response);
      snack("Произошла ошибка", "error");
    }
  }

  const formik = useFormik({
    initialValues: initialValues[!actionId ? 0 : 1],
    validationSchema: validationSchemas[!actionId ? 0 : 1],
    onSubmit,
  })

  const { signXML } = useSignature();

  const [isSigning, setIsSigning] = useState(false);
  const [signingError, setSigningError] = useState("");


  const storageType = useDictionary(
      StorageType,
      (type) => ({value: type, label: t(`storage_type.${type}`)}),
      { value: StorageType.PKCS12, label: t("storage_type.PKCS12") }
  );

  async function onSign() {
    try {
      const signedXml = await signXML(`<root></root>`, storageType!.selected!.value as StorageType);

      if (!signedXml) return;
      const data: Response<VerifySubject> = await authService.verifyXml({xml: signedXml, form: companyForm } );
      if(!data.data) {
        return
      }
      formik.setFieldValue("lastName", data.data.lastName, true)
      formik.setFieldValue("firstName", data.data.firstName, true)
      formik.setFieldValue("iinOrBin", data.data.iinOrBin, true);
      if(companyForm !== CompanyForm.kz_ip) {
        formik.setFieldValue("orgName", data.data.orgName, true)
      }


    } catch (e: any) {
      console.error(e)
      snack(t("snack.error.no_ncalayer"), "error")
    } finally {
      console.log('Finally')
    }
  }

  return (
    <Box>
      {/*<Box marginBottom={4}>*/}
        {/*<Typography*/}
        {/*  sx={{*/}
        {/*    textTransform: 'uppercase',*/}
        {/*    fontWeight: 'medium',*/}
        {/*  }}*/}
        {/*  gutterBottom*/}
        {/*  color={'textSecondary'}*/}
        {/*>*/}
        {/*  {t("pages.signup")}*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*  variant="h4"*/}
        {/*  sx={{*/}
        {/*    fontWeight: 700,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {t("signup_page.create_account")}*/}
        {/*</Typography>*/}
        <Typography color="text.secondary">
        {t("signup_page.fill_form")}
        </Typography>
      {/*</Box>*/}

          <SignatureBtn
              onSign={onSign}
              isSigning={isSigning}
              signingError={signingError}
              storageType={storageType}
          />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.enter_first_name")}
            </Typography>
            <TextField
              label={`${t("inputs.first_name")} *`}
              variant="outlined"
              name={'firstName'}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              disabled={true}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              InputProps={{ readOnly: !actionId ? false : true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.enter_last_name")}
            </Typography>
            <TextField
              label={`${t("inputs.last_name")} *`}
              variant="outlined"
              name={'lastName'}
              disabled={true}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              InputProps={{ readOnly: !actionId ? false : true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}  sx={{alignSelf: "end"}}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.new_enter_bin")}
            </Typography>
            <TextField
                label={`${t("inputs.bin")} *`}
                variant="outlined"
                name={'iinOrBin'}
                fullWidth
                value={formik.values.iinOrBin}
                onChange={formik.handleChange}
                disabled={true}
                error={
                    formik.touched.iinOrBin && Boolean(formik.errors.iinOrBin)
                }
                helperText={formik.touched.firstName && formik.errors.iinOrBin}
                InputProps={{ readOnly: !actionId ? false : true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.new_enter_org_name")}
            </Typography>
            <TextField
                label={`${t("inputs.new_org_name")} *`}
                variant="outlined"
                name={'orgName'}
                disabled={companyForm !== CompanyForm.kz_ip}
                fullWidth
                value={formik.values.orgName}
                onChange={formik.handleChange}
                error={formik.touched.orgName && Boolean(formik.errors.orgName)}
                helperText={formik.touched.orgName && formik.errors.orgName}
                InputProps={{ readOnly: !actionId ? false : true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.enter_email")}
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{ readOnly: !actionId ? false : true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.enter_phone")}
            </Typography>
            <TextField
              label={`${t("inputs.phone")} *`}
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                inputComponent: PhoneMaskInputComponent,
                readOnly: !actionId ? false : true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.enter_password")}
            </Typography>
            <TextField
              label={`${t("inputs.password")} *`}
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                readOnly: !actionId ? false : true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              {t("signup_page.confirm_password")}
            </Typography>
            <TextField
              label={`${t("inputs.confirm_password")} *`}
              variant="outlined"
              name={'сonfirmPassword'}
              type={'password'}
              fullWidth
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              InputProps={{
                readOnly: !actionId ? false : true
              }}
            />
          </Grid>
          {actionId
            ? <Grid item xs={12}>
                <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                  {t("signup_page.enter_code_from_email")}
                </Typography>
                <TextField
                  label={`${t("inputs.code")} *`}
                  variant="outlined"
                  name={'code'}
                  type={'text'}
                  fullWidth
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
            : null
          }
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  {t("signup_page.already_have_account")}{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/page-login'}
                    underline={'none'}
                  >
                    {t("pages.login")}
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                {!actionId
                  ? t("signup_page.continue")
                  : t("signup_page.do_signup")
                }
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'textSecondary'}
              align={'center'}
            >
              {t("signup_page.by_clicking_on_button")}
              {!actionId
                ? ` "${t("signup_page.continue")}" `
                : ` "${t("signup_page.do_signup")}" `
              }
              {t("signup_page.you_agree_with_our")}
              <br />
              <Link
                component={'a'}
                color={'primary'}
                href={'/page-privacy'}
                underline={'none'}
              >
                {t("signup_page.offer")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
