import { useEffect, useState } from "react";
import { Button, Divider, Grid, LinearProgress, Paper, TextField, 
  Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/store";
import { useSnack } from "../../../utils/useSnack";
import { Company } from "../../../types/company";
import { companyService } from "../../../api/services/company";
import { CLEAR_COMPANY, SET_COMPANY } from "../../../redux/reducers/company";
import digitsOnly from "../../../utils/digitsOnly";

export default function CompanyDetails() {
  const { snack } = useSnack();
  const { t } = useTranslation();
  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const company: Company | null = state.company.company;
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const companyId = company ? company.id : pathArray[pathArray.length - 1];

  const navigate = useNavigate();
  const {text, page, pageSize} =  location.state
  const onClose = () => {

    if(!location?.state) {
      navigate("/moderator-companies");
      return
    }
    let params = `?page=${page}&pageSize=${pageSize}`
    if(text) {
      params += `&text=${text}`
    }
    navigate(`/moderator-companies${params}`);
  }


  const [loading, setLoading] = useState(false);
  async function getCompany() {
    setLoading(true);
    try {
      const response = await companyService.getCompany(companyId);
      if (response.status === "success" && response.data) { 
        dispatch({ type: SET_COMPANY, payload: response.data })
      }
      else { snack("Произошла ошибка при получении данных организации", "error") }
    }
    catch (error) { const err = error as AxiosError }
    finally { setLoading(false) }
  }

  useEffect(() => {
    if (companyId) getCompany();
    return () => { dispatch({ type: CLEAR_COMPANY }) }
  }, [companyId]);

  return (
    <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">
            {company?.id
              ? `${t("cabinet.company_details.profile")} ${company ? `- ${company.shortTitle}` : ""}`
              : `${t("cabinet.company_details.card")} ${company ? `- ${company.shortTitle}` : ""}`
            }
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 6, width: "100%" }} />

      {loading
        ? <Box>
            <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
            <Typography>{t("cabinet.company_details.loading")}</Typography>
          </Box>
        : !company 
            ? <Box>
                <Typography>
                  {t("cabinet.company_details.error")}
                </Typography>
              </Box>
            : <Grid container rowSpacing={3}>
                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("cabinet.company_details.owner")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.owner_email")}
                      value={company.ownerData.username}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.owner_name")}
                      value={company.ownerData.name}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.owner_phone")}
                      value={company.ownerData.mobile}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("cabinet.company_details.ceo")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.ceo_phone")}
                      value={company.ceoPhone}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.ceo_name")}
                      value={company.ceoName}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("cabinet.company_details.organization")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.itn")}
                      value={digitsOnly(company.itn)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.title")}
                      value={company.title}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.short_title")}
                      value={company.shortTitle}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.legal_form")}
                      value={t(`legal_form.${company.legalForm}`)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.size")}
                      value={t(`size.${company.size}`)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.ownership_form")}
                      value={t(`ownership.${company.ownershipForm}`)}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      {t("cabinet.company_details.contacts")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.legal_address")}
                      value={company.legalAddress}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.actual_address")}
                      value={company.actualAddress}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.phone")}
                      value={company.phone}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth color="primary" type="text" size="small"
                      label={t("auction.fields.company.email")}
                      value={company.email}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
      }

      {!company?.id
        ? null
        : <>
            <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

            <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
              <Grid item xs="auto">
                <Button fullWidth variant="contained" size="large"
                        onClick={onClose} color="secondary"
                >
                  {t("cabinet.company_details.close")}
                </Button>
              </Grid>
            </Grid>
          </>
      }
      
		</Paper>
  )
}
