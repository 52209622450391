import {t} from "i18next";
import * as Yup from "yup";
import { NewAuctionTermsForm } from "../../../../../types/newAuction";

export const initialValues: NewAuctionTermsForm = {
  deliveryCondition: { value: "", label: "" },
  deliveryTime: "",
};

export function ValidationSchema() {
  return Yup.object({
    deliveryCondition: Yup.object({ 
      value: Yup.string().required(t("validation.required")), 
    }),
    deliveryTime: Yup.string().required(t("validation.required")),
  });
}
