import { AxiosResponse } from "axios";
import { Response } from "../../types/api";
import { LoginData, SignupFirstStepData, SignupFinalStepData,
  ForgotPasswordFirstStepData,
  User} from "../../types/auth";
import {LoginResponseData, Token, VerifySubject} from "../../types/token";
import protectedInstance from "../instances/protected";
import publicInstance from "../instances/public";
import {CompanyForm} from "../../utils/enums";
import {Verify} from "crypto";

async function signupFirstStep(data: SignupFirstStepData, recaptchaToken: string) {
  return publicInstance
    .post(`/v1/public/user/register/${recaptchaToken}`, data)
    .then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

async function signupFinalStep(data: SignupFinalStepData, actionId: string) {
  return publicInstance
    .post(`/v1/public/approve/${actionId}`, data)
    .then((res: AxiosResponse<Response<null>>) => res.data)
}

async function forgotPasswordFirstStep(data: ForgotPasswordFirstStepData, recaptchaToken: string) {
  return publicInstance
    .post(`/v1/public/user/restore/${recaptchaToken}`, data)
    .then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

const forgotPasswordFinalStep = signupFinalStep;

async function login(data: LoginData) {
  return publicInstance
    .post("/v1/public/token/obtain", data)
    .then((res: AxiosResponse<Response<LoginResponseData>>) => res.data)
}

async function refreshToken(data: { refreshToken: string }) {
  return publicInstance
    .post("/v1/public/token/refresh", data)
    .then((res: AxiosResponse<Response<Token>>) => res.data)
}

async function verifyXml(data: { xml: string, form: CompanyForm }): Promise<Response<VerifySubject>> {
  return publicInstance
      .post("/v2/public/user/verify", data)
      .then((res: AxiosResponse<Response<VerifySubject>>) => res.data)
}

async function getUser() {
  return protectedInstance
    .get("/user")
    .then((res: AxiosResponse<Response<User>>) => res.data)
}



export const authService = {
  signupFirstStep,
  signupFinalStep,
  forgotPasswordFirstStep,
  forgotPasswordFinalStep,
  login,
  refreshToken,
  verifyXml,
  getUser
};
