import { AnyAction } from "redux"

export const SET_USER = "SET_USER";

const initialState = {
  user: null,
}

export const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_USER: return { ...state,
      user: action.payload
    }

    default: return state
  }
}
