/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typed from 'react-typed';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RocketIllustration from '../../../../../svg/illustrations/Rocket';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../../../../types/token';
import { Authority } from '../../../../../utils/enums';
import { useToken } from '../../../../../utils/useToken';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const { token } = useToken();
  const decoded: DecodedToken | null = token ? jwtDecode(token.access_token) : null;

  function cabinetOrLoginLink() {
    if (!decoded) { return "/page-login" }

    switch(decoded.authorities[decoded.authorities.length - 1]) {
      case Authority.USER: return "/account-introduction"
      case Authority.MODERATOR: return "/moderator-introduction"
      case Authority.ADMIN: return "/admin-introduction"

      default: return "/account-introduction"
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              color="textPrimary"
              sx={{
                fontWeight: 700,
              }}
            >
              {t("home_page.hero.text1")}
              <br />
              {t("home_page.hero.text2")}
              <br />
              <Typography
                color={'primary'}
                component={'span'}
                variant={'inherit'}
              >
                <Typed
                  strings={t("home_page.hero.text3").split(",")}
                  typeSpeed={80}
                  loop={true}
                />
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="textSecondary"
              sx={{ fontWeight: 400 }}
            >
              {t("home_page.hero.text4")}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          >
            <Button
              component={'a'}
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
              href={cabinetOrLoginLink()}
            >
              {token ? t("pages.cabinet") : t("pages.login")}
            </Button>
            <Box
              marginTop={{ xs: 2, sm: 0 }}
              marginLeft={{ sm: 2 }}
              width={{ xs: '100%', md: 'auto' }}
            >
              <Button
                component={'a'}
                href={'/page-signup'}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
              >
                {t("pages.signup")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={'100%'} width={'100%'} maxHeight={600}>
            <RocketIllustration width={'100%'} height={'100%'} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
